// FileUploader.tsx
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../styles/components/FileUploader.scss';

interface FileUploaderProps {
  onFileSelect: (event: any) => void;
  loading: boolean;
  file: File | null;
  accept: string;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  onFileSelect,
  loading,
  file,
  accept
}) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.[0]) {
      onFileSelect({ target: { files: acceptedFiles } });
    }
  }, [onFileSelect]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    multiple: false
  });

  return (
    <div className="file-uploader-container">
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''} ${file ? 'has-file' : ''}`}>
        <input {...getInputProps()} />
        {loading ? (
          <div className="upload-status">
            <div className="upload-icon-wrapper">
              <svg className="animate-spin" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" />
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
              </svg>
              <span className="main-text">Processing...</span>
            </div>
          </div>
        ) : file ? (
          <div className="upload-status">
            <div className="status-content">
              <div className="upload-icon-wrapper success">
                <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div className="upload-text">
                <span className="filename">{file.name}</span>
                <span className="file-size">{(file.size / 1024 / 1024).toFixed(2)} MB</span>
              </div>
            </div>
            <button className="change-file" onClick={(e) => {
              e.stopPropagation();
              onFileSelect({ target: { files: null } });
            }}>
              Change
            </button>
          </div>
        ) : (
          <div className="upload-content">
            <div className="upload-icon-wrapper">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"/>
              </svg>
            </div>
            <div className="upload-text">
              <span className="main-text">Drop your resume here</span>
              <span className="sub-text">or</span>
              <button className="upload-button">Browse Files</button>
            </div>
            <div className="file-types">
              <span className="file-type">PDF</span>
              <span className="separator">·</span>
              <span className="file-type">DOCX</span>
              <span className="file-size-limit">(Max 10MB)</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
