import axios from "axios";
import { mainUrl } from "./constant";
import { jwtDecode } from 'jwt-decode';

const axiosClient = axios.create({
      baseURL: mainUrl,
    });
    
    // Check if the token is expired
    const isTokenExpired = (token: string): boolean => {
      try {
        const decoded: any = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000); // current time in seconds
        return decoded.exp < currentTime;
      } catch (error) {
        return true; // if decoding fails, treat token as expired
      }
    };
    
    // Create a function to refresh the token
    const refreshAccessToken = async () => {
      const refreshToken = localStorage.getItem("refreshToken");
      
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }
      
      try {
        const response = await axios.post(`${mainUrl}auth/refresh-tokens`, {
          refreshToken, // assuming you pass refreshToken in body
        });
        
        const { accessToken, refreshToken: newRefreshToken } = response.data;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", newRefreshToken);
        
        return accessToken;
      } catch (error) {
        console.error("Failed to refresh token", error);
        throw error;
      }
    };
    
    // Request Interceptor
    axiosClient.interceptors.request.use(async (config) => {
      let token = localStorage.getItem("accessToken");
    
      if (token && isTokenExpired(token)) {
        try {
          token = await refreshAccessToken(); // Get new token if expired
        } catch (error) {
          console.log("Could not refresh token", error);
          // Optionally log out the user or handle the error
          localStorage.clear(); // Clear tokens
          window.location.href = '/auth/login'; // Redirect to login
          throw error;
        }
      }
    
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      
      return config;
    }, (error) => {
      return Promise.reject(error);
    });
    
    // Response Interceptor to catch 401 errors (unauthorized)
    axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;
    
        // If the error is 401 (Unauthorized) and it hasn't been retried yet
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const newAccessToken = await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
            return axiosClient(originalRequest); // Retry original request with new token
          } catch (err) {
            console.error("Token refresh failed", err);
            localStorage.clear(); // Clear tokens
            window.location.href = '/auth/login'; // Redirect to login
            return Promise.reject(err);
          }
        }
    
        return Promise.reject(error);
      }
    );
    

export default axiosClient;