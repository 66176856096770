// export const mainUrl = "http://localhost:3000/v1/"

export const mainUrl = "https://backend.quickcruit.ai/v1/"

export const locationOptions = [
      { value: 'san_francisco_bay_area', label: '🌉 San Francisco Bay Area' },
      { value: 'greater_seattle_area', label: '🌲 Greater Seattle Area' },
      { value: 'new_york_metropolitan_area', label: '🗽 New York Metropolitan Area' },
      { value: 'greater_los_angeles_area', label: '🌴 Greater Los Angeles Area' },
      { value: 'boston_metropolitan_area', label: '🏙️ Boston Metropolitan Area' },
      { value: 'chicago_metropolitan_area', label: '🌬️ Chicago Metropolitan Area' },
      { value: 'washington_dc_metro_area', label: '🏛️ Washington D.C. Metro Area' },
      { value: 'austin_metropolitan_area', label: '🎸 Austin Metropolitan Area' },
      { value: 'dallas_fort_worth_metroplex', label: '🤠 Dallas-Fort Worth Metroplex' },
      { value: 'atlanta_metropolitan_area', label: '🍑 Atlanta Metropolitan Area' },
      { value: 'denver_metropolitan_area', label: '🏔️ Denver Metropolitan Area' },
      { value: 'phoenix_metropolitan_area', label: '🌵 Phoenix Metropolitan Area' },
      { value: 'open_to_all', label: '🌍 Open to All Locations' }
];


export const industryOptions = [
      { value: 'Software Engineering', label: '💻 Software Engineering' },
      { value: 'Data Science', label: '📊 Data Science' },
      { value: 'Cybersecurity & IT', label: '🔒 Cybersecurity & IT' },
      { value: 'Product', label: '🛠️ Product' },
      { value: 'Marketing', label: '📣 Marketing' },
      { value: 'Sales', label: '💼 Sales' },
      { value: 'Finance', label: '💰 Finance' },  
      { value: 'Consulting', label: '🧑‍💼 Consulting' },
      { value: 'Logistics & Operations', label: '📦 Logistics & Operations' },
      { value: 'UI/UX & Design', label: '🎨 UI/UX & Design' },
      { value: 'Mechanical Engineering', label: '⚙️ Mechanical Engineering' },
      { value: 'Electrical Engineering', label: '🔌 Electrical Engineering' },
      { value: 'Civil Engineering', label: '🏗️ Civil Engineering' },
      { value: 'Chemical Engineering', label: '⚗️ Chemical Engineering' },
      { value: 'Aerospace Engineering', label: '✈️ Aerospace Engineering' },
      { value: 'Robotics & Automation', label: '🤖 Robotics & Automation' },
      { value: 'Biomedical Engineering', label: '🧬 Biomedical Engineering' },
      { value: 'Accounting', label: '📈 Accounting' }
];