import React, { useEffect, useState } from "react";
import { Icon } from '@iconify/react';
import "../../styles/components/landing-pages/companyLogos.scss";

// Update the LogoProps interface (replace lines 6-11)
interface LogoProps {
  icon: string;
  style?: { color?: string };
  className?: string;
  size?: number;
}

const allLogos = [
  <img src="/company-logos/google.png" alt="Google" className="logo-item special-logo" />,
  <Icon icon="simple-icons:apple" className="logo-item" style={{ color: '#A3AAAE' }} />,
  <Icon icon="simple-icons:microsoft" className="logo-item" style={{ color: '#00A4EF' }} />,
  <Icon icon="simple-icons:netflix" className="logo-item" style={{ color: '#E50914' }} />,
  <Icon icon="simple-icons:salesforce" className="logo-item" style={{ color: '#00A1E0' }} />,
  <Icon icon="simple-icons:linkedin" className="logo-item" style={{ color: '#0A66C2' }} />,
  <img src="/company-logos/amazon.png" alt="Amazon" className="logo-item special-logo" />,
  <Icon icon="simple-icons:cisco" className="logo-item" style={{ color: '#1BA0D7' }} />,
  <Icon icon="simple-icons:dell" className="logo-item" style={{ color: '#007DB8' }} />,
  <img src="/company-logos/walmart.png" alt="Walmart" className="logo-item special-logo" />,
  <Icon icon="simple-icons:intel" className="logo-item" style={{ color: '#0071C5' }} />,
  <Icon icon="simple-icons:ibm" className="logo-item" style={{ color: '#052FAD' }} />,
  <Icon icon="simple-icons:adobe" className="logo-item" style={{ color: '#FF0000' }} />,
  <Icon icon="simple-icons:meta" className="logo-item" style={{ color: '#0668E1' }} />,
  <Icon icon="simple-icons:spacex" className="logo-item" style={{ color: '#000000' }} />,
  <Icon icon="simple-icons:uber" className="logo-item" style={{ color: '#000000' }} />,
  <Icon icon="simple-icons:spotify" className="logo-item" style={{ color: '#1DB954' }} />,
  <Icon icon="simple-icons:tiktok" className="logo-item" style={{ color: '#000000' }} />,
  <Icon icon="simple-icons:airbnb" className="logo-item" style={{ color: '#FF5A5F' }} />,
  <Icon icon="simple-icons:tesla" className="logo-item" style={{ color: '#CC0000' }} />,
  <Icon icon="simple-icons:notion" className="logo-item" style={{ color: '#000000' }} />,
  <Icon icon="simple-icons:zoom" className="logo-item" style={{ color: '#2D8CFF' }} />,
  <Icon icon="simple-icons:samsung" className="logo-item" style={{ color: '#1428A0' }} />,
  <Icon icon="simple-icons:nike" className="logo-item" style={{ color: '#000000' }} />
];

const getNextLogos = (startIndex: number) => {
  const normalizedIndex = startIndex % allLogos.length;
  let topRow = [];
  let bottomRow = [];

  // Fill top row (6 items)
  for (let i = 0; i < 6; i++) {
    const index = (normalizedIndex + i) % allLogos.length;
    topRow.push(allLogos[index]);
  }

  // Fill bottom row (4 items)
  for (let i = 0; i < 4; i++) {
    const index = (normalizedIndex + 6 + i) % allLogos.length;
    bottomRow.push(allLogos[index]);
  }

  return { top: topRow, bottom: bottomRow };
};

const CompanyLogos: React.FC = () => {
  const [visibleLogos, setVisibleLogos] = useState(getNextLogos(0));
  const [index, setIndex] = useState(0);
  const [fadeOut, setFadeOut] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) return;
    
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 5) % allLogos.length);
        setFadeOut(false);
      }, 800);
    }, 5000);

    return () => clearInterval(interval);
  }, [isPaused]);

  useEffect(() => {
    setVisibleLogos(getNextLogos(index));
  }, [index]);

  return (
    <div 
      className="company-logos-container"
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      <p className="company-logos-heading">
        500,000+ Jobs from Top Companies, Startups, and Everything in
        Between, Refreshed Daily!
      </p>
      <div className={`company-logos-grid ${fadeOut ? "fade-out" : "fade-in"}`}>
        <div className="logos-row">
          {visibleLogos.top.map((logo, idx) => (
            <div key={idx} className="logo-wrapper">
              {React.isValidElement(logo) && logo.type === Icon ? 
                React.cloneElement(logo as React.ReactElement<LogoProps>, {
                  icon: (logo as React.ReactElement<LogoProps>).props.icon,
                  style: (logo as React.ReactElement<LogoProps>).props.style,
                  className: (logo as React.ReactElement<LogoProps>).props.className,
                  size: 32
                }) : logo}
            </div>
          ))}
        </div>
        <div className="logos-row">
          {visibleLogos.bottom.map((logo, idx) => (
            <div key={idx} className="logo-wrapper">
              {React.isValidElement(logo) && logo.type === Icon ? 
                React.cloneElement(logo as React.ReactElement<LogoProps>, {
                  icon: (logo as React.ReactElement<LogoProps>).props.icon,
                  style: (logo as React.ReactElement<LogoProps>).props.style,
                  className: (logo as React.ReactElement<LogoProps>).props.className,
                  size: 32
                }) : logo}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompanyLogos;
