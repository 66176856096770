// animations/components/ResumeHighlight.tsx
import React, { useState, useEffect } from "react";
import styles from "../styles/ResumeHighlight.module.scss";
import resumeSvg from "../assets/resume.svg";
import tailorWithAiSvg from "../assets/tailor_with_ai.svg";
import suggestionSvg from "../assets/suggestion.svg";
import cursorSvg from "../assets/cursor.svg";

const ResumeHighlight: React.FC = () => {
  useEffect(() => {
    const animationLoop = () => {
      const obj = document.querySelector('.resumeBackground') as HTMLObjectElement;
      const svg = obj?.contentDocument?.querySelector('svg');
      if (!svg) return;

      // Clean up existing elements
      const existingElements = svg.querySelectorAll('rect[y="470"], .tailor-button, .cursor, .suggestion');
      existingElements.forEach(el => el.remove());

      // Create selection rectangle
      const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      rect.setAttribute('x', '67');
      rect.setAttribute('y', '470');
      rect.setAttribute('width', '0');
      rect.setAttribute('height', '15');
      rect.setAttribute('rx', '2');
      rect.setAttribute('fill', '#0033A0');
      rect.setAttribute('fill-opacity', '0.3');
      svg.appendChild(rect);

      // Create tailor button (adjusted Y position)
      const button = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      button.setAttributeNS('http://www.w3.org/1999/xlink', 'href', tailorWithAiSvg);
      button.setAttribute('x', '290');
      button.setAttribute('y', '492'); // Moved up 3px
      button.setAttribute('width', '123');
      button.setAttribute('height', '44');
      button.style.opacity = '0';
      button.style.transition = 'opacity 0.3s ease';
      button.classList.add('tailor-button');
      svg.appendChild(button);

      // Create cursor with adjusted positioning
      const cursor = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      cursor.setAttributeNS('http://www.w3.org/1999/xlink', 'href', cursorSvg);
      cursor.setAttribute('x', '-50');
      cursor.setAttribute('y', '508'); // Slightly lower to point at button center
      cursor.setAttribute('width', '24');
      cursor.setAttribute('height', '24');
      cursor.style.transition = 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)'; // Slightly slower
      cursor.classList.add('cursor');
      svg.appendChild(cursor);

      // Create suggestion (adjusted Y position)
      const suggestion = document.createElementNS('http://www.w3.org/2000/svg', 'image');
      suggestion.setAttributeNS('http://www.w3.org/1999/xlink', 'href', suggestionSvg);
      suggestion.setAttribute('x', '67');
      suggestion.setAttribute('y', '492'); // Matched with button position
      suggestion.setAttribute('width', '577');
      suggestion.setAttribute('height', '124');
      suggestion.style.opacity = '0';
      suggestion.style.transition = 'all 0.4s cubic-bezier(0.4, 0, 0.2, 1)'; // Smoother transition
      suggestion.classList.add('suggestion');
      svg.appendChild(suggestion);

      // Animate selection
      let width = 0;
      const targetWidth = 577;
      const animate = () => {
        if (width < targetWidth) {
          width += 10;
          rect.setAttribute('width', width.toString());
          requestAnimationFrame(animate);
        } else {
          // Improved animation sequence
          setTimeout(() => {
            button.style.opacity = '1';
            
            setTimeout(() => {
              // Move cursor to slightly off-center of button
              cursor.style.transform = 'translate(370px, 8px)'; // Adjusted X and Y for better positioning
              
              setTimeout(() => {
                // Natural click animation
                cursor.style.transform = 'translate(335px, 10px) scale(0.9)'; // Keep X position, adjust Y for click
                
                setTimeout(() => {
                  cursor.style.transform = 'translate(335px, 8px) scale(1)';
                  
                  setTimeout(() => {
                    // Smoother transition to suggestion
                    button.style.opacity = '0';
                    cursor.style.opacity = '0';
                    suggestion.style.opacity = '1';
                    suggestion.style.transform = 'translateY(0)';
                  }, 150);
                }, 100);
              }, 450); // Slightly longer pause before click
            }, 350); // Slightly longer movement
          }, 100);
        }
      };

      requestAnimationFrame(animate);
    };

    // Start animation when SVG loads
    const obj = document.querySelector('.resumeBackground') as HTMLObjectElement;
    obj?.addEventListener('load', () => {
      animationLoop();
      setInterval(animationLoop, 7000);
    });
  }, []);

  return (
    <div className="hero-image-wrapper">
      <object 
        data={resumeSvg} 
        type="image/svg+xml"
        className="resumeBackground"
      />
    </div>
  );
};

export default ResumeHighlight;
