import React, { useState, useEffect, useRef } from "react";
import "../../styles/components/landing-pages/landingPage.scss";
import Navbar from "./Navbar";
import SignUpComponent from "../SignUpComponent";
import CompanyLogos from "./CompanyLogos";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import ResumeHighlight from "../../animations/components/ResumeHighlight"; // Import the animated resume component
import heroImage from "../../assets/demo.png";
import featureImage1 from "../../assets/feature1.png";
import featureImage2 from "../../assets/feature2.png";
import featureImage3 from "../../assets/feature3.png";
import featureImage4 from "../../assets/feature4.png";
import featureImage5 from "../../assets/feature5.png";

const buttonsData = [
  {
    icon: "📖",
    label: "Dashboard",
    imgUrl: featureImage1,
  },
  {
    icon: "📄",
    label: "Resume Builder",
    imgUrl: featureImage2,
  },
  {
    icon: "🎯",
    label: "CV Builder",
    imgUrl: featureImage3,
  },
  {
    icon: "🤖",
    label: "Portfolio",
    imgUrl: featureImage4,
  },
  {
    icon: "📅",
    label: "Job Search",
    imgUrl: featureImage5,
  },
  {
    icon: "🌐",
    label: "Autofill Jobs",
    imgUrl: heroImage,
  },
];

const LandingPage: React.FC = () => {
  const [selectedImage, setSelectedImage] = useState(buttonsData[0].imgUrl);
  const [currentIndex, setCurrentIndex] = useState(0); // Current image index
  const [scrollY, setScrollY] = useState(0);
  const [currentFeature, setCurrentFeature] = useState<number | null>(null);
  const [previousFeature, setPreviousFeature] = useState<number | null>(null);
  const [isImageVisible, setImageVisible] = useState(false);
  const [hasScrolledOut, setHasScrolledOut] = useState(false);
  const featureRefs = useRef<HTMLDivElement[]>([]);
  const featureHighlightRef = useRef<HTMLDivElement>(null);
  const delay = 3000;
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null); // Ref to keep track of the timeout

  // Lines 65 - 96 is in regards to the carousel image feature influenced from Notion

  // Function to reset auto-sliding
  const resetAutoSlide = () => {
    // Clear the existing timer (if any) before starting a new one
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Start a new timer for the next image
    timeoutRef.current = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % buttonsData.length);
    }, delay);
  };

  // Function to handle changing image manually
  const handleButtonClick = (index: number) => {
    setCurrentIndex(index); // Set current image to the clicked button's index
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear the existing timeout to reset the timer
    }
    resetAutoSlide(); // Immediately reset the timer after manual selection
  };

  // Auto-slide to the next image every 3 seconds
  useEffect(() => {
    resetAutoSlide(); // Start auto-sliding when the component mounts or when currentIndex changes

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current); // Cleanup timeout on unmount
      }
    };
  }, [currentIndex]); // Re-run effect whenever the currentIndex changes

  // IntersectionObserver to update currentFeature
  useEffect(() => {
    const featureSections = featureRefs.current;

    const intersectingFeatures = new Map<number, number>(); // index -> intersectionRatio

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = featureRefs.current.indexOf(
            entry.target as HTMLDivElement
          );

          if (entry.isIntersecting) {
            intersectingFeatures.set(index, entry.intersectionRatio);
            (entry.target as HTMLDivElement).classList.add("in-view"); // Make text visible
          } else {
            intersectingFeatures.delete(index);
            (entry.target as HTMLDivElement).classList.remove("in-view");
          }
        });

        if (intersectingFeatures.size > 0) {
          // Find the feature with the highest intersection ratio
          let mostVisibleFeatureIndex = -1;
          let maxRatio = -1;
          intersectingFeatures.forEach((ratio, index) => {
            if (ratio > maxRatio) {
              maxRatio = ratio;
              mostVisibleFeatureIndex = index;
            }
          });

          if (
            mostVisibleFeatureIndex !== -1 &&
            mostVisibleFeatureIndex !== currentFeature
          ) {
            setPreviousFeature(currentFeature);
            setCurrentFeature(mostVisibleFeatureIndex);
          }
        } else {
          if (currentFeature !== null) {
            setPreviousFeature(currentFeature);
          }
          setCurrentFeature(null);
        }
      },
      {
        root: null,
        threshold: 0.5, // Trigger when 50% of the element is in view
      }
    );

    // Observing all feature sections
    featureSections.forEach((section) => observer.observe(section));

    return () => observer.disconnect(); // Cleanup on unmount
  }, [currentFeature]);

  // Reset previousFeature after animation
  useEffect(() => {
    if (previousFeature !== null && previousFeature !== currentFeature) {
      const timeout = setTimeout(() => {
        setPreviousFeature(null);
      }, 600); // Match CSS animation duration

      return () => clearTimeout(timeout);
    }
  }, [previousFeature, currentFeature]);

  // Scroll handler to control image visibility (for both first and last features)
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);

      // First Feature: Entry and exit in the lower half
      if (currentFeature === 0 && featureRefs.current[0]) {
        const featureElement = featureRefs.current[0];
        const rect = featureElement.getBoundingClientRect();
        const featureCenter = rect.top + rect.height / 2;
        const viewportCenter = window.innerHeight / 2;
        const distance = featureCenter - viewportCenter;

        const threshold = 100; // Distance threshold for image appearance and disappearance

        // Show image if the first feature is in the lower half
        if (distance > threshold && distance < viewportCenter) {
          setImageVisible(false); // Fade-out image if it goes below the viewport center
        } else if (distance <= threshold) {
          setImageVisible(true); // Keep image visible in upper half and center
        }
      }

      // Last Feature: Entry and exit in the top half
      const lastFeatureIndex = featureRefs.current.length - 1;
      if (
        currentFeature === lastFeatureIndex &&
        featureRefs.current[lastFeatureIndex]
      ) {
        const featureElement = featureRefs.current[lastFeatureIndex];
        const rect = featureElement.getBoundingClientRect();
        const featureCenter = rect.top + rect.height / 2;
        const distanceToTop = featureCenter - window.innerHeight / 2; // Compare to top half of the screen

        const threshold = 100; // Distance threshold for image appearance and disappearance

        // Show image if the last feature is in the top half
        if (distanceToTop < threshold && distanceToTop > -threshold) {
          setImageVisible(true); // Fade-in image when entering the top half
        } else if (distanceToTop < -threshold) {
          setImageVisible(false); // Fade-out image when it leaves the top half
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Call initially to set the state

    return () => window.removeEventListener("scroll", handleScroll);
  }, [isImageVisible, currentFeature]);

  // Reset visibility when scrolling out of the feature-highlight-section
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            setImageVisible(false); // Reset the image visibility when scrolling out
            setHasScrolledOut(true); // Track that the user has scrolled out of the section
          } else {
            if (hasScrolledOut) {
              setHasScrolledOut(false); // Reset the scroll-out flag when entering again
            }
          }
        });
      },
      {
        root: null,
        threshold: 0, // Trigger when any part of the section is not in view
      }
    );

    if (featureHighlightRef.current) {
      observer.observe(featureHighlightRef.current);
    }

    return () => {
      if (featureHighlightRef.current) {
        observer.unobserve(featureHighlightRef.current); // Cleanup observer on unmount
      }
    };
  }, [hasScrolledOut]);

  const featureImages = [
    featureImage1,
    featureImage2,
    featureImage3,
    featureImage4,
    featureImage5,
  ];

  return (
    <>
      <div className={`landing-container ${scrollY > 50 ? "scrolled" : ""}`}>
        <Navbar />
        <section className="hero-section">
          <div className="hero-content">
            <h1 className="supercharge-your-job">
              Supercharge Your Job Search with AI
            </h1>
            <p className="enhance-your-application">
              <b>Enhance your application</b> with tailored resumes,
              personalized cover letters,
              <b> ensure ATS compatibility</b>, and comprehensive job search
              tools—all powered by our AI-driven platform.
            </p>
            <Link to="/auth/signup" className="cta-btn">
              Get Started - It's Free
            </Link>
          </div>
          <div className="hero-image-container">
            {/* Integrate animated ResumeHighlight component instead of static hero image */}
            <div className="resume-highlight-container">
              <ResumeHighlight />
            </div>
          </div>
        </section>
        <section
          className={`credibility-section ${scrollY > 400 ? "visible" : ""}`}
        >
          <CompanyLogos />
        </section>
        {/* commented out the big picture as placeholder */}
        {/* <section className="demo-section">
          <img
            src={demoPlaceholder}
            alt="Demo Placeholder"
            className="demo-image"
          />
        </section> */}
        {/* <section>
          <div className="carousel-container">
            <div className="image-display">
              <img
                src={buttonsData[currentIndex].imgUrl}
                alt={buttonsData[currentIndex].label}
              />
            </div>

            <div className="button-group">
              {buttonsData.map((button, index) => (
                <button
                  key={index}
                  className={`carousel-button ${
                    index === currentIndex ? "active" : ""
                  }`} // Add 'active' class to the selected button
                  onClick={() => handleButtonClick(index)}
                >
                  <span className="icon">{button.icon}</span>
                  <span className="label">{button.label}</span>
                </button>
              ))}
            </div>
          </div>
        </section> */}
        {/* <section className="features-section">
          <h2 className="features-heading">Built by Job Seekers, for Job Seekers</h2>
          <div className="features-container">
            <div className="feature">
              <span className="feature-icon">🎯</span>
              <h3>Find Your Dream Job</h3>
              <p>AI-powered job matches that fit your skills and aspirations.</p>
            </div>
            <div className="feature">
              <span className="feature-icon">📄</span>
              <h3>Build ATS-Compatible Resumes</h3>
              <p>Create professional resumes with our AI-powered builder.</p>
            </div>
            <div className="feature">
              <span className="feature-icon">⚙️</span>
              <h3>Streamline Your Applications</h3>
              <p>Auto-fill job forms quickly and accurately.</p>
            </div>
            <div className="feature">
              <span className="feature-icon">📈</span>
              <h3>Stay Organized and Informed</h3>
              <p>Track and manage your job search effortlessly.</p>
            </div>
          </div>
        </section> */}

        {/* Feature Highlight Section */}
        <section
          className="feature-highlight-section"
          ref={featureHighlightRef}
        >
          {/* Left-hand side scrolling content */}
          <div className="scrolling-content">
            <div
              className="feature-section"
              ref={(el) => (featureRefs.current[0] = el!)}
            >
              <h2>The Right Job, Right Away</h2>
              <p>
                Our AI analyzes your skills and experience to find the jobs
                you're made for. No more endless scrolling.
              </p>
            </div>
            <div
              className="feature-section"
              ref={(el) => (featureRefs.current[1] = el!)}
            >
              <h2>Tailor Your Resume and Cover Letter with AI Precision</h2>
              <p>
                QuickCruit optimizes both your resume and cover letter to
                impress recruiters and pass ATS filters.
              </p>
            </div>
            <div
              className="feature-section"
              ref={(el) => (featureRefs.current[2] = el!)}
            >
              <h2>Apply in Seconds</h2>
              <p>
                QuickCruit auto-fills your job applications, ensuring accuracy
                and saving you hours of tedious work.
              </p>
            </div>
            <div
              className="feature-section"
              ref={(el) => (featureRefs.current[3] = el!)}
            >
              <h2>Turn Your Profile into a Portfolio</h2>
              <p>
                Showcase your skills, achievements, and experience with a
                shareable portfolio—no sign-in required.
              </p>
            </div>
            <div
              className="feature-section"
              ref={(el) => (featureRefs.current[4] = el!)}
            >
              <h2>Your Data, Protected</h2>
              <p>
                Your privacy is our priority. We never share your information,
                and you control what's public.
              </p>
            </div>
          </div>

          {/* Right-hand side image */}
          {isImageVisible && (
            <div
              className={`static-image-container ${
                isImageVisible ? "active" : ""
              }`}
            >
              {previousFeature !== null &&
                previousFeature !== currentFeature && (
                  <img
                    key={`prev-${previousFeature}`}
                    src={featureImages[previousFeature]}
                    alt="Feature Visual"
                    className="feature-image feature-image-exit"
                  />
                )}
              {currentFeature !== null && (
                <img
                  key={`curr-${currentFeature}`}
                  src={featureImages[currentFeature]}
                  alt="Feature Visual"
                  className="feature-image feature-image-enter"
                />
              )}
            </div>
          )}
        </section>
        <SignUpComponent />
      </div>

      <Footer />
    </>
  );
};

export default LandingPage;
