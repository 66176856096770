import React, { useEffect, useState, useRef, useCallback } from "react";
import "../../styles/components/landing-pages/jobPage.scss";
import Navbar from "./Navbar";
import ResumeHighlight from "../../animations/components/ResumeHighlight";
import feature2 from "../../assets/feature2.png";
import feature3 from "../../assets/feature3.png";
import feature4 from "../../assets/feature4.png";
import Footer from "./Footer";
import jobDescriptionIcon from "../../assets/job-details.svg";
import jobTile from "../../assets/job-tile.svg";
import { Link } from "react-router-dom";
import JobTile from "../../animations/components/JobTile";
import NewJobBenefits from "../../animations/components/NewJobBenefits";

const JobPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const demoSectionRef = useRef<HTMLDivElement | null>(null);
  const heroSectionRef = useRef<HTMLElement | null>(null);
  const [demoSectionDimensions, setDemoSectionDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [heroHeight, setHeroHeight] = useState(0);
  const NAVBAR_HEIGHT = 88; // pixels
  const [isCaptionVisible, setIsCaptionVisible] = useState(false);
  const [hasExpanded, setHasExpanded] = useState(false);
  const [activeGroup, setActiveGroup] = useState<'days5' | 'days10' | 'days15' | null>(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Get dimensions after component mounts
  useEffect(() => {
    if (demoSectionRef.current) {
      const rect = demoSectionRef.current.getBoundingClientRect();
      setDemoSectionDimensions({
        width: rect.width,
        height: rect.height,
      });
    }
    if (heroSectionRef.current) {
      const rect = heroSectionRef.current.getBoundingClientRect();
      setHeroHeight(rect.height);
    }
  }, []);

  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      if (demoSectionRef.current) {
        const rect = demoSectionRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        const startScroll = rect.top - viewportHeight * 1.8;
        const endScroll = rect.top + (viewportHeight * 0.8);
        const scrollRange = endScroll - startScroll;
        
        const currentScroll = window.scrollY - startScroll;
        const progress = Math.max(0, Math.min(1, currentScroll / scrollRange));
        
        setScrollProgress(progress);
        
        if (progress >= 1 && !hasExpanded) {
          setHasExpanded(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    requestAnimationFrame(handleScroll);
    
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasExpanded]);

  // Calculate transforms
  const progress = scrollProgress || 0;

  // For hero section
  const heroTranslateY = -progress * 100;
  const heroOpacity = Math.max(1 - progress * 2, 0);

  // For demo section
  const maxScaleX = window.innerWidth / demoSectionDimensions.width;
  const maxScaleY = (window.innerHeight - NAVBAR_HEIGHT) / demoSectionDimensions.height;
  const maxScale = Math.min(1.25, maxScaleX, maxScaleY); // Limit scale to 1.25x

  const demoScale = 1 + (progress * (maxScale - 1)); // This makes it scale up
  const deltaHeight = demoSectionDimensions.height * (demoScale - 1);
  const demoTranslateY = progress * -50; // Slight upward movement while scaling

  useEffect(() => {
    if (progress >= 1 && !isCaptionVisible) {
      setIsCaptionVisible(true);
    }
  }, [progress]);

  useEffect(() => {
    if (progress >= 1 && !hasExpanded) {
      setHasExpanded(true);
    }
  }, [progress]);

  const calculateProgress = useCallback(() => {
    if (!demoSectionRef.current) return 0;
    
    const rect = demoSectionRef.current.getBoundingClientRect();
    const viewportHeight = window.innerHeight - NAVBAR_HEIGHT; // Subtract navbar height
    const elementTop = rect.top - NAVBAR_HEIGHT; // Adjust element position
    
    // Adjust the calculation to account for navbar
    const progress = Math.max(0, Math.min(1, 1 - (elementTop / viewportHeight)));
    
    return progress;
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Navbar />
      <div className="job-page-container">
        <section
          ref={heroSectionRef}
          className="job-features-hero-section"
        >
          <div className="hero-content">
            <h1 className="hero-heading">
            Unlock Your Next Opportunity
            </h1>
            <p className="hero-subheading">
            Access over 500,000 live job postings, updated daily and tailored
            to your skills. Your perfect fit is just a click away.
            </p>
            <Link to="/auth/signup" className="cta-btn">
              Get Started - It's Free
            </Link>
          </div>
          <div className="hero-image-container">
            <div className="resume-highlight-container">
              <ResumeHighlight />
            </div>
          </div>
        </section>

        <section className="job-demo-section" ref={demoSectionRef}>
          <div className="demo-container">
            <h2 className="demo-heading">Job Details That Just Make Sense</h2>
            <div className="demo-animation-container">
              <img
                src={jobDescriptionIcon}
                alt="Job Description Demo"
                className={`demo-icon ${hasExpanded && !isMobile ? 'expanded' : ''}`}
                style={!hasExpanded && !isMobile ? { 
                  '--scale': `${1 + (progress * 5.67)}`
                } as React.CSSProperties : {}}
              />
            </div>
          </div>
        </section>

        <section className="job-features-section">
          {/* Feature 1: Text Left, Image Right */}
          <div className="feature-row">
            <div className="feature-content">
              <h2>Stay Ahead with Fresh Opportunities</h2>
              <p>
                Explore a vast array of positions, all posted within the last 15
                days, ensuring you have access to the most current openings.
              </p>
            </div>
            <div className="feature-image">
              <NewJobBenefits />
            </div>
          </div>

          {/* Feature 2: Image Left, Text Right */}
          <div className="feature-row">
            <div className="feature-image">
              <img src={feature2} alt="Compose feature" />
            </div>
            <div className="feature-content">
              <h2>AI-Powered Matches Just for You</h2>
              <p>
                Receive daily job recommendations tailored to your profile,
                streamlining your search and connecting you with roles that
                align with your career goals.
              </p>
            </div>
          </div>

          {/* Feature 3: Text Left, Image Right */}
          <div className="feature-row">
            <div className="feature-content">
              <h2>See How New a Job Is Instantly</h2>
              <p>
                Our intuitive design lets you prioritize the latest listings
                effortlessly, helping you focus on the freshest opportunities
                first.
              </p>
            </div>
            <div className="feature-image">
              <JobTile />
            </div>
          </div>

          {/* Feature 4: Image Left, Text Right */}
          <div className="feature-row">
            <div className="feature-image">
              <img src={feature4} alt="App shortcuts feature" />
            </div>
            <div className="feature-content">
              <h2>A Job Page That Works for You</h2>
              <p>
                Access a personalized page featuring your daily tailored job
                matches, all presented in a user-friendly layout to enhance your
                job search experience.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default JobPage;
