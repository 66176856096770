export interface JobOption {
    value: string;
    label: string;
  }
  
  export const jobTitles: JobOption[] = [
    // Technology & Software (expanded)
    { value: 'software_engineer', label: 'Software Engineer' },
    { value: 'associate_software_engineer', label: 'Associate Software Engineer' },
    { value: 'frontend_developer', label: 'Frontend Developer' },
    { value: 'associate_frontend_developer', label: 'Associate Frontend Developer' },
    { value: 'backend_developer', label: 'Backend Developer' },
    { value: 'associate_backend_developer', label: 'Associate Backend Developer' },
    { value: 'fullstack_developer', label: 'Full Stack Developer' },
    { value: 'associate_fullstack_developer', label: 'Associate Full Stack Developer' },
    { value: 'mobile_developer', label: 'Mobile Developer' },
    { value: 'associate_mobile_developer', label: 'Associate Mobile Developer' },
    { value: 'devops_engineer', label: 'DevOps Engineer' },
    { value: 'associate_devops_engineer', label: 'Associate DevOps Engineer' },
    { value: 'site_reliability_engineer', label: 'Site Reliability Engineer' },
    { value: 'associate_site_reliability_engineer', label: 'Associate Site Reliability Engineer' },
    { value: 'cloud_architect', label: 'Cloud Architect' },
    { value: 'systems_architect', label: 'Systems Architect' },
    { value: 'qa_engineer', label: 'QA Engineer' },
    { value: 'security_engineer', label: 'Security Engineer' },
    { value: 'blockchain_developer', label: 'Blockchain Developer' },
    { value: 'smart_contract_developer', label: 'Smart Contract Developer' },
    { value: 'ios_developer', label: 'iOS Developer' },
    { value: 'android_developer', label: 'Android Developer' },
    { value: 'react_native_developer', label: 'React Native Developer' },
    { value: 'flutter_developer', label: 'Flutter Developer' },
    { value: 'unity_developer', label: 'Unity Developer' },
    { value: 'game_developer', label: 'Game Developer' },
    { value: 'ar_vr_developer', label: 'AR/VR Developer' },
    { value: 'embedded_systems_engineer', label: 'Embedded Systems Engineer' },
    { value: 'firmware_engineer', label: 'Firmware Engineer' },
    { value: 'robotics_engineer', label: 'Robotics Engineer' },
    { value: 'network_engineer', label: 'Network Engineer' },
    { value: 'system_administrator', label: 'System Administrator' },
    { value: 'database_administrator', label: 'Database Administrator' },
    { value: 'cloud_solutions_architect', label: 'Cloud Solutions Architect' },
    { value: 'aws_specialist', label: 'AWS Specialist' },
    { value: 'azure_specialist', label: 'Azure Specialist' },
    { value: 'gcp_specialist', label: 'GCP Specialist' },
    { value: 'kubernetes_engineer', label: 'Kubernetes Engineer' },
    { value: 'docker_specialist', label: 'Docker Specialist' },
    { value: 'ci_cd_engineer', label: 'CI/CD Engineer' },
    { value: 'release_engineer', label: 'Release Engineer' },
    { value: 'performance_engineer', label: 'Performance Engineer' },
    { value: 'reliability_engineer', label: 'Reliability Engineer' },
  
    // Cybersecurity (expanded)
    { value: 'security_architect', label: 'Security Architect' },
    { value: 'penetration_tester', label: 'Penetration Tester' },
    { value: 'security_analyst', label: 'Security Analyst' },
    { value: 'security_consultant', label: 'Security Consultant' },
    { value: 'threat_intelligence_analyst', label: 'Threat Intelligence Analyst' },
    { value: 'incident_response_engineer', label: 'Incident Response Engineer' },
    { value: 'security_operations_engineer', label: 'Security Operations Engineer' },
    { value: 'cryptographer', label: 'Cryptographer' },
    { value: 'forensics_analyst', label: 'Forensics Analyst' },
    { value: 'compliance_specialist', label: 'Compliance Specialist' },
    { value: 'identity_and_access_management_specialist', label: 'Identity and Access Management Specialist' },
    { value: 'security_awareness_trainer', label: 'Security Awareness Trainer' },
    { value: 'vulnerability_assessment_analyst', label: 'Vulnerability Assessment Analyst' },
    { value: 'application_security_engineer', label: 'Application Security Engineer' },
    { value: 'cybersecurity_manager', label: 'Cybersecurity Manager' },
  
    // Data & AI (expanded)
    { value: 'data_scientist', label: 'Data Scientist' },
    { value: 'data_engineer', label: 'Data Engineer' },
    { value: 'data_analyst', label: 'Data Analyst' },
    { value: 'machine_learning_engineer', label: 'Machine Learning Engineer' },
    { value: 'business_intelligence_analyst', label: 'Business Intelligence Analyst' },
    { value: 'ai_researcher', label: 'AI Researcher' },
    { value: 'nlp_engineer', label: 'NLP Engineer' },
    { value: 'computer_vision_engineer', label: 'Computer Vision Engineer' },
    { value: 'deep_learning_engineer', label: 'Deep Learning Engineer' },
    { value: 'data_architect', label: 'Data Architect' },
    { value: 'etl_developer', label: 'ETL Developer' },
    { value: 'data_warehouse_engineer', label: 'Data Warehouse Engineer' },
    { value: 'analytics_engineer', label: 'Analytics Engineer' },
    { value: 'data_governance_specialist', label: 'Data Governance Specialist' },
    { value: 'data_quality_analyst', label: 'Data Quality Analyst' },
    { value: 'quantitative_analyst', label: 'Quantitative Analyst' },
    { value: 'data_visualization_specialist', label: 'Data Visualization Specialist' },
    { value: 'big_data_engineer', label: 'Big Data Engineer' },
    { value: 'statistician', label: 'Statistician' },
    { value: 'decision_scientist', label: 'Decision Scientist' },
    { value: 'bioinformatics_specialist', label: 'Bioinformatics Specialist' },
  
    // Product & Design (expanded)
    { value: 'product_manager', label: 'Product Manager' },
    { value: 'associate_product_manager', label: 'Associate Product Manager' },
    { value: 'product_owner', label: 'Product Owner' },
    { value: 'product_designer', label: 'Product Designer' },
    { value: 'ux_designer', label: 'UX Designer' },
    { value: 'ui_designer', label: 'UI Designer' },
    { value: 'ux_researcher', label: 'UX Researcher' },
    { value: 'visual_designer', label: 'Visual Designer' },
    { value: 'design_systems_engineer', label: 'Design Systems Engineer' },
    { value: 'interaction_designer', label: 'Interaction Designer' },
    { value: 'motion_designer', label: 'Motion Designer' },
    { value: 'service_designer', label: 'Service Designer' },
    { value: 'design_ops_manager', label: 'Design Ops Manager' },
    { value: 'design_researcher', label: 'Design Researcher' },
    { value: 'information_architect', label: 'Information Architect' },
    { value: 'content_designer', label: 'Content Designer' },
    { value: 'accessibility_specialist', label: 'Accessibility Specialist' },
    { value: 'brand_designer', label: 'Brand Designer' },
    { value: 'creative_director', label: 'Creative Director' },
    { value: 'user_experience_architect', label: 'User Experience Architect' },
    { value: 'industrial_designer', label: 'Industrial Designer' },
  
    // Digital Marketing & Growth (expanded)
    { value: 'growth_manager', label: 'Growth Manager' },
    { value: 'seo_specialist', label: 'SEO Specialist' },
    { value: 'sem_specialist', label: 'SEM Specialist' },
    { value: 'conversion_rate_optimizer', label: 'Conversion Rate Optimizer' },
    { value: 'marketing_automation_specialist', label: 'Marketing Automation Specialist' },
    { value: 'email_marketing_specialist', label: 'Email Marketing Specialist' },
    { value: 'social_media_manager', label: 'Social Media Manager' },
    { value: 'content_marketing_manager', label: 'Content Marketing Manager' },
    { value: 'affiliate_marketing_manager', label: 'Affiliate Marketing Manager' },
    { value: 'performance_marketing_specialist', label: 'Performance Marketing Specialist' },
    { value: 'digital_strategist', label: 'Digital Strategist' },
    { value: 'influencer_marketing_manager', label: 'Influencer Marketing Manager' },
    { value: 'brand_manager', label: 'Brand Manager' },
    { value: 'public_relations_specialist', label: 'Public Relations Specialist' },
    { value: 'community_manager', label: 'Community Manager' },
    { value: 'marketing_data_analyst', label: 'Marketing Data Analyst' },
    { value: 'pay_per_click_specialist', label: 'Pay Per Click Specialist' },
    { value: 'ecommerce_manager', label: 'E-commerce Manager' },
    { value: 'growth_hacker', label: 'Growth Hacker' },
    { value: 'product_marketing_manager', label: 'Product Marketing Manager' },
  
    // Management & Leadership (expanded)
    { value: 'engineering_manager', label: 'Engineering Manager' },
    { value: 'technical_lead', label: 'Technical Lead' },
    { value: 'project_manager', label: 'Project Manager' },
    { value: 'program_manager', label: 'Program Manager' },
    { value: 'scrum_master', label: 'Scrum Master' },
    { value: 'agile_coach', label: 'Agile Coach' },
    { value: 'chief_technology_officer', label: 'Chief Technology Officer' },
    { value: 'chief_executive_officer', label: 'Chief Executive Officer' },
    { value: 'chief_operating_officer', label: 'Chief Operating Officer' },
    { value: 'chief_financial_officer', label: 'Chief Financial Officer' },
    { value: 'chief_marketing_officer', label: 'Chief Marketing Officer' },
    { value: 'director_of_engineering', label: 'Director of Engineering' },
    { value: 'vp_of_product', label: 'VP of Product' },
    { value: 'general_manager', label: 'General Manager' },
    { value: 'team_lead', label: 'Team Lead' },
    { value: 'business_unit_manager', label: 'Business Unit Manager' },
    { value: 'chief_data_officer', label: 'Chief Data Officer' },
    { value: 'chief_information_security_officer', label: 'Chief Information Security Officer' },
    { value: 'chief_experience_officer', label: 'Chief Experience Officer' },
    { value: 'head_of_operations', label: 'Head of Operations' },
  
    // Business & Operations (expanded)
    { value: 'business_analyst', label: 'Business Analyst' },
    { value: 'operations_manager', label: 'Operations Manager' },
    { value: 'account_manager', label: 'Account Manager' },
    { value: 'sales_representative', label: 'Sales Representative' },
    { value: 'marketing_manager', label: 'Marketing Manager' },
    { value: 'digital_marketing_specialist', label: 'Digital Marketing Specialist' },
    { value: 'content_strategist', label: 'Content Strategist' },
    { value: 'business_development_manager', label: 'Business Development Manager' },
    { value: 'strategy_consultant', label: 'Strategy Consultant' },
    { value: 'customer_relationship_manager', label: 'Customer Relationship Manager' },
    { value: 'sales_manager', label: 'Sales Manager' },
    { value: 'supply_chain_manager', label: 'Supply Chain Manager' },
    { value: 'logistics_coordinator', label: 'Logistics Coordinator' },
    { value: 'procurement_specialist', label: 'Procurement Specialist' },
    { value: 'operations_analyst', label: 'Operations Analyst' },
    { value: 'business_process_manager', label: 'Business Process Manager' },
    { value: 'event_planner', label: 'Event Planner' },
    { value: 'franchise_manager', label: 'Franchise Manager' },
    { value: 'retail_manager', label: 'Retail Manager' },
    { value: 'category_manager', label: 'Category Manager' },
  
    // Finance & Accounting (expanded)
    { value: 'financial_analyst', label: 'Financial Analyst' },
    { value: 'accountant', label: 'Accountant' },
    { value: 'investment_banker', label: 'Investment Banker' },
    { value: 'financial_advisor', label: 'Financial Advisor' },
    { value: 'tax_specialist', label: 'Tax Specialist' },
    { value: 'auditor', label: 'Auditor' },
    { value: 'credit_analyst', label: 'Credit Analyst' },
    { value: 'treasury_analyst', label: 'Treasury Analyst' },
    { value: 'risk_manager', label: 'Risk Manager' },
    { value: 'payroll_specialist', label: 'Payroll Specialist' },
    { value: 'budget_analyst', label: 'Budget Analyst' },
    { value: 'cost_accountant', label: 'Cost Accountant' },
    { value: 'controller', label: 'Controller' },
    { value: 'hedge_fund_manager', label: 'Hedge Fund Manager' },
    { value: 'portfolio_manager', label: 'Portfolio Manager' },
    { value: 'quantitative_trader', label: 'Quantitative Trader' },
    { value: 'forensic_accountant', label: 'Forensic Accountant' },
    { value: 'mergers_and_acquisitions_analyst', label: 'Mergers and Acquisitions Analyst' },
    { value: 'financial_planner', label: 'Financial Planner' },
    { value: 'chief_financial_officer', label: 'Chief Financial Officer' },
  
    // Healthcare & Science (expanded)
    { value: 'research_scientist', label: 'Research Scientist' },
    { value: 'clinical_research_coordinator', label: 'Clinical Research Coordinator' },
    { value: 'biomedical_engineer', label: 'Biomedical Engineer' },
    { value: 'data_scientist_healthcare', label: 'Healthcare Data Scientist' },
    { value: 'pharmacist', label: 'Pharmacist' },
    { value: 'medical_lab_technician', label: 'Medical Lab Technician' },
    { value: 'biostatistician', label: 'Biostatistician' },
    { value: 'genetic_counselor', label: 'Genetic Counselor' },
    { value: 'healthcare_administrator', label: 'Healthcare Administrator' },
    { value: 'public_health_specialist', label: 'Public Health Specialist' },
    { value: 'epidemiologist', label: 'Epidemiologist' },
    { value: 'clinical_data_manager', label: 'Clinical Data Manager' },
    { value: 'regulatory_affairs_specialist', label: 'Regulatory Affairs Specialist' },
    { value: 'medical_writer', label: 'Medical Writer' },
    { value: 'quality_assurance_specialist', label: 'Quality Assurance Specialist' },
    { value: 'health_informatics_specialist', label: 'Health Informatics Specialist' },
    { value: 'medical_device_sales_representative', label: 'Medical Device Sales Representative' },
    { value: 'occupational_therapist', label: 'Occupational Therapist' },
    { value: 'speech_language_pathologist', label: 'Speech-Language Pathologist' },
    { value: 'physical_therapist', label: 'Physical Therapist' },
  
    // Human Resources (expanded)
    { value: 'hr_manager', label: 'HR Manager' },
    { value: 'recruiter', label: 'Recruiter' },
    { value: 'talent_acquisition_specialist', label: 'Talent Acquisition Specialist' },
    { value: 'hr_business_partner', label: 'HR Business Partner' },
    { value: 'compensation_and_benefits_manager', label: 'Compensation and Benefits Manager' },
    { value: 'training_and_development_manager', label: 'Training and Development Manager' },
    { value: 'employee_relations_specialist', label: 'Employee Relations Specialist' },
    { value: 'organizational_development_consultant', label: 'Organizational Development Consultant' },
    { value: 'diversity_and_inclusion_manager', label: 'Diversity and Inclusion Manager' },
    { value: 'hr_analyst', label: 'HR Analyst' },
    { value: 'hr_coordinator', label: 'HR Coordinator' },
    { value: 'talent_management_specialist', label: 'Talent Management Specialist' },
    { value: 'benefits_administrator', label: 'Benefits Administrator' },
    { value: 'hr_information_systems_specialist', label: 'HR Information Systems Specialist' },
    { value: 'labor_relations_specialist', label: 'Labor Relations Specialist' },
    { value: 'recruiting_coordinator', label: 'Recruiting Coordinator' },
    { value: 'hr_generalist', label: 'HR Generalist' },
    { value: 'hr_director', label: 'HR Director' },
    { value: 'chief_human_resources_officer', label: 'Chief Human Resources Officer' },
    { value: 'hr_consultant', label: 'HR Consultant' },
  
    // Innovation & Emerging Tech (expanded)
    { value: 'innovation_manager', label: 'Innovation Manager' },
    { value: 'emerging_tech_researcher', label: 'Emerging Tech Researcher' },
    { value: 'quantum_computing_engineer', label: 'Quantum Computing Engineer' },
    { value: 'iot_specialist', label: 'IoT Specialist' },
    { value: '3d_printing_specialist', label: '3D Printing Specialist' },
    { value: 'digital_transformation_consultant', label: 'Digital Transformation Consultant' },
    { value: 'technology_evangelist', label: 'Technology Evangelist' },
    { value: 'solutions_architect', label: 'Solutions Architect' },
    { value: 'edge_computing_engineer', label: 'Edge Computing Engineer' },
    { value: 'virtual_reality_developer', label: 'Virtual Reality Developer' },
    { value: 'augmented_reality_developer', label: 'Augmented Reality Developer' },
    { value: 'drone_operator', label: 'Drone Operator' },
    { value: 'nanotechnology_engineer', label: 'Nanotechnology Engineer' },
    { value: 'autonomous_vehicle_engineer', label: 'Autonomous Vehicle Engineer' },
    { value: 'renewable_energy_specialist', label: 'Renewable Energy Specialist' },
    { value: 'cyber_ethicist', label: 'Cyber Ethicist' },
    { value: 'haptic_interface_designer', label: 'Haptic Interface Designer' },
    { value: 'biotech_researcher', label: 'Biotech Researcher' },
    { value: 'spacecraft_systems_engineer', label: 'Spacecraft Systems Engineer' },
    { value: 'chief_innovation_officer', label: 'Chief Innovation Officer' },
  
    // Operations & Support (expanded)
    { value: 'technical_support_engineer', label: 'Technical Support Engineer' },
    { value: 'customer_success_manager', label: 'Customer Success Manager' },
    { value: 'implementation_specialist', label: 'Implementation Specialist' },
    { value: 'technical_account_manager', label: 'Technical Account Manager' },
    { value: 'solutions_consultant', label: 'Solutions Consultant' },
    { value: 'developer_advocate', label: 'Developer Advocate' },
    { value: 'developer_relations_manager', label: 'Developer Relations Manager' },
    { value: 'technical_writer', label: 'Technical Writer' },
    { value: 'documentation_specialist', label: 'Documentation Specialist' },
    { value: 'knowledge_base_manager', label: 'Knowledge Base Manager' },
    { value: 'field_service_engineer', label: 'Field Service Engineer' },
    { value: 'customer_support_specialist', label: 'Customer Support Specialist' },
    { value: 'application_support_engineer', label: 'Application Support Engineer' },
    { value: 'it_support_specialist', label: 'IT Support Specialist' },
    { value: 'operations_coordinator', label: 'Operations Coordinator' },
    { value: 'service_delivery_manager', label: 'Service Delivery Manager' },
    { value: 'service_desk_analyst', label: 'Service Desk Analyst' },
    { value: 'client_services_manager', label: 'Client Services Manager' },
    { value: 'escalation_engineer', label: 'Escalation Engineer' },
    { value: 'release_manager', label: 'Release Manager' },
  
    // Legal & Compliance
    { value: 'corporate_counsel', label: 'Corporate Counsel' },
    { value: 'compliance_officer', label: 'Compliance Officer' },
    { value: 'legal_assistant', label: 'Legal Assistant' },
    { value: 'contract_manager', label: 'Contract Manager' },
    { value: 'paralegal', label: 'Paralegal' },
    { value: 'regulatory_affairs_manager', label: 'Regulatory Affairs Manager' },
    { value: 'intellectual_property_attorney', label: 'Intellectual Property Attorney' },
    { value: 'litigation_specialist', label: 'Litigation Specialist' },
    { value: 'data_protection_officer', label: 'Data Protection Officer' },
    { value: 'chief_compliance_officer', label: 'Chief Compliance Officer' },
    { value: 'ethics_officer', label: 'Ethics Officer' },
    { value: 'legal_operations_manager', label: 'Legal Operations Manager' },
    { value: 'privacy_counsel', label: 'Privacy Counsel' },
    { value: 'labor_and_employment_attorney', label: 'Labor and Employment Attorney' },
    { value: 'corporate_secretary', label: 'Corporate Secretary' },
    { value: 'legal_affairs_manager', label: 'Legal Affairs Manager' },
    { value: 'antitrust_lawyer', label: 'Antitrust Lawyer' },
    { value: 'chief_legal_officer', label: 'Chief Legal Officer' },
    { value: 'immigration_specialist', label: 'Immigration Specialist' },
    { value: 'compliance_analyst', label: 'Compliance Analyst' },
  
    // Education & Training
    { value: 'training_manager', label: 'Training Manager' },
    { value: 'learning_and_development_specialist', label: 'Learning and Development Specialist' },
    { value: 'instructional_designer', label: 'Instructional Designer' },
    { value: 'corporate_trainer', label: 'Corporate Trainer' },
    { value: 'e_learning_specialist', label: 'E-Learning Specialist' },
    { value: 'curriculum_developer', label: 'Curriculum Developer' },
    { value: 'training_coordinator', label: 'Training Coordinator' },
    { value: 'facilitator', label: 'Facilitator' },
    { value: 'education_consultant', label: 'Education Consultant' },
    { value: 'chief_learning_officer', label: 'Chief Learning Officer' },
    { value: 'training_and_development_manager', label: 'Training and Development Manager' },
    { value: 'talent_development_specialist', label: 'Talent Development Specialist' },
    { value: 'learning_experience_designer', label: 'Learning Experience Designer' },
    { value: 'technical_trainer', label: 'Technical Trainer' },
    { value: 'leadership_development_coach', label: 'Leadership Development Coach' },
    { value: 'education_program_manager', label: 'Education Program Manager' },
    { value: 'organizational_development_specialist', label: 'Organizational Development Specialist' },
    { value: 'career_coach', label: 'Career Coach' },
    { value: 'employee_engagement_specialist', label: 'Employee Engagement Specialist' },
    { value: 'mentor', label: 'Mentor' },
  
    // Customer Service
    { value: 'customer_service_representative', label: 'Customer Service Representative' },
    { value: 'call_center_agent', label: 'Call Center Agent' },
    { value: 'customer_experience_manager', label: 'Customer Experience Manager' },
    { value: 'client_relationship_specialist', label: 'Client Relationship Specialist' },
    { value: 'customer_support_manager', label: 'Customer Support Manager' },
    { value: 'customer_care_specialist', label: 'Customer Care Specialist' },
    { value: 'help_desk_technician', label: 'Help Desk Technician' },
    { value: 'service_quality_manager', label: 'Service Quality Manager' },
    { value: 'customer_service_coordinator', label: 'Customer Service Coordinator' },
    { value: 'customer_retention_specialist', label: 'Customer Retention Specialist' },
    { value: 'technical_support_specialist', label: 'Technical Support Specialist' },
    { value: 'client_success_specialist', label: 'Client Success Specialist' },
    { value: 'customer_insights_analyst', label: 'Customer Insights Analyst' },
    { value: 'service_recovery_specialist', label: 'Service Recovery Specialist' },
    { value: 'call_center_manager', label: 'Call Center Manager' },
    { value: 'customer_loyalty_manager', label: 'Customer Loyalty Manager' },
    { value: 'user_support_specialist', label: 'User Support Specialist' },
    { value: 'client_services_director', label: 'Client Services Director' },
    { value: 'customer_experience_analyst', label: 'Customer Experience Analyst' },
    { value: 'customer_onboarding_specialist', label: 'Customer Onboarding Specialist' },
  
    // Supply Chain & Logistics
    { value: 'supply_chain_analyst', label: 'Supply Chain Analyst' },
    { value: 'logistics_manager', label: 'Logistics Manager' },
    { value: 'inventory_planner', label: 'Inventory Planner' },
    { value: 'procurement_manager', label: 'Procurement Manager' },
    { value: 'warehouse_supervisor', label: 'Warehouse Supervisor' },
    { value: 'demand_planner', label: 'Demand Planner' },
    { value: 'transportation_coordinator', label: 'Transportation Coordinator' },
    { value: 'import_export_specialist', label: 'Import/Export Specialist' },
    { value: 'supply_chain_manager', label: 'Supply Chain Manager' },
    { value: 'purchasing_agent', label: 'Purchasing Agent' },
    { value: 'materials_manager', label: 'Materials Manager' },
    { value: 'fleet_manager', label: 'Fleet Manager' },
    { value: 'operations_planner', label: 'Operations Planner' },
    { value: 'customs_broker', label: 'Customs Broker' },
    { value: 'distribution_manager', label: 'Distribution Manager' },
    { value: 'logistics_engineer', label: 'Logistics Engineer' },
    { value: 'supply_chain_director', label: 'Supply Chain Director' },
    { value: 'warehouse_operations_manager', label: 'Warehouse Operations Manager' },
    { value: 'vendor_manager', label: 'Vendor Manager' },
    { value: 'commodity_manager', label: 'Commodity Manager' },
  
    // Manufacturing & Production
    { value: 'manufacturing_engineer', label: 'Manufacturing Engineer' },
    { value: 'production_supervisor', label: 'Production Supervisor' },
    { value: 'quality_control_inspector', label: 'Quality Control Inspector' },
    { value: 'process_engineer', label: 'Process Engineer' },
    { value: 'plant_manager', label: 'Plant Manager' },
    { value: 'assembly_line_worker', label: 'Assembly Line Worker' },
    { value: 'production_planner', label: 'Production Planner' },
    { value: 'maintenance_technician', label: 'Maintenance Technician' },
    { value: 'industrial_engineer', label: 'Industrial Engineer' },
    { value: 'machine_operator', label: 'Machine Operator' },
    { value: 'lean_manufacturing_specialist', label: 'Lean Manufacturing Specialist' },
    { value: 'production_manager', label: 'Production Manager' },
    { value: 'fabrication_specialist', label: 'Fabrication Specialist' },
    { value: 'safety_coordinator', label: 'Safety Coordinator' },
    { value: 'supply_chain_planner', label: 'Supply Chain Planner' },
    { value: 'metrology_engineer', label: 'Metrology Engineer' },
    { value: 'manufacturing_technician', label: 'Manufacturing Technician' },
    { value: 'operations_manager', label: 'Operations Manager' },
    { value: 'tool_and_die_maker', label: 'Tool and Die Maker' },
    { value: 'production_analyst', label: 'Production Analyst' },
  
    // Real Estate & Construction
    { value: 'construction_manager', label: 'Construction Manager' },
    { value: 'real_estate_agent', label: 'Real Estate Agent' },
    { value: 'architect', label: 'Architect' },
    { value: 'civil_engineer', label: 'Civil Engineer' },
    { value: 'property_manager', label: 'Property Manager' },
    { value: 'urban_planner', label: 'Urban Planner' },
    { value: 'interior_designer', label: 'Interior Designer' },
    { value: 'site_supervisor', label: 'Site Supervisor' },
    { value: 'quantity_surveyor', label: 'Quantity Surveyor' },
    { value: 'project_estimator', label: 'Project Estimator' },
    { value: 'building_inspector', label: 'Building Inspector' },
    { value: 'leasing_consultant', label: 'Leasing Consultant' },
    { value: 'facility_manager', label: 'Facility Manager' },
    { value: 'mechanical_engineer', label: 'Mechanical Engineer' },
    { value: 'electrical_engineer', label: 'Electrical Engineer' },
    { value: 'environmental_engineer', label: 'Environmental Engineer' },
    { value: 'construction_project_manager', label: 'Construction Project Manager' },
    { value: 'surveying_technician', label: 'Surveying Technician' },
    { value: 'landscape_architect', label: 'Landscape Architect' },
    { value: 'construction_laborer', label: 'Construction Laborer' },
  
    // Hospitality & Tourism
    { value: 'hotel_manager', label: 'Hotel Manager' },
    { value: 'event_coordinator', label: 'Event Coordinator' },
    { value: 'travel_agent', label: 'Travel Agent' },
    { value: 'restaurant_manager', label: 'Restaurant Manager' },
    { value: 'chef', label: 'Chef' },
    { value: 'tour_guide', label: 'Tour Guide' },
    { value: 'concierge', label: 'Concierge' },
    { value: 'front_desk_agent', label: 'Front Desk Agent' },
    { value: 'housekeeping_manager', label: 'Housekeeping Manager' },
    { value: 'food_and_beverage_manager', label: 'Food and Beverage Manager' },
    { value: 'banquet_manager', label: 'Banquet Manager' },
    { value: 'sommelier', label: 'Sommelier' },
    { value: 'hospitality_consultant', label: 'Hospitality Consultant' },
    { value: 'catering_manager', label: 'Catering Manager' },
    { value: 'spa_manager', label: 'Spa Manager' },
    { value: 'casino_dealer', label: 'Casino Dealer' },
    { value: 'guest_relations_manager', label: 'Guest Relations Manager' },
    { value: 'tourism_marketing_specialist', label: 'Tourism Marketing Specialist' },
    { value: 'cruise_ship_director', label: 'Cruise Ship Director' },
    { value: 'adventure_tour_operator', label: 'Adventure Tour Operator' },
  
    // Media & Entertainment
    { value: 'film_producer', label: 'Film Producer' },
    { value: 'video_editor', label: 'Video Editor' },
    { value: 'graphic_designer', label: 'Graphic Designer' },
    { value: 'copywriter', label: 'Copywriter' },
    { value: 'content_creator', label: 'Content Creator' },
    { value: 'social_media_influencer', label: 'Social Media Influencer' },
    { value: 'sound_engineer', label: 'Sound Engineer' },
    { value: 'photographer', label: 'Photographer' },
    { value: 'art_director', label: 'Art Director' },
    { value: 'animation_specialist', label: 'Animation Specialist' },
    { value: 'broadcast_engineer', label: 'Broadcast Engineer' },
    { value: 'journalist', label: 'Journalist' },
    { value: 'editor', label: 'Editor' },
    { value: 'publicist', label: 'Publicist' },
    { value: 'radio_producer', label: 'Radio Producer' },
    { value: 'talent_agent', label: 'Talent Agent' },
    { value: 'music_producer', label: 'Music Producer' },
    { value: 'set_designer', label: 'Set Designer' },
    { value: 'game_designer', label: 'Game Designer' },
    { value: 'script_writer', label: 'Script Writer' },
  
    // Information Technology (additional roles)
    { value: 'it_manager', label: 'IT Manager' },
    { value: 'systems_analyst', label: 'Systems Analyst' },
    { value: 'it_project_manager', label: 'IT Project Manager' },
    { value: 'business_systems_analyst', label: 'Business Systems Analyst' },
    { value: 'erp_consultant', label: 'ERP Consultant' },
    { value: 'crm_specialist', label: 'CRM Specialist' },
    { value: 'sap_consultant', label: 'SAP Consultant' },
    { value: 'oracle_developer', label: 'Oracle Developer' },
    { value: 'sharepoint_administrator', label: 'SharePoint Administrator' },
    { value: 'data_center_engineer', label: 'Data Center Engineer' },
    { value: 'virtualization_engineer', label: 'Virtualization Engineer' },
    { value: 'it_auditor', label: 'IT Auditor' },
    { value: 'helpdesk_support', label: 'Helpdesk Support' },
    { value: 'network_administrator', label: 'Network Administrator' },
    { value: 'system_engineer', label: 'System Engineer' },
    { value: 'desktop_support_engineer', label: 'Desktop Support Engineer' },
    { value: 'linux_system_administrator', label: 'Linux System Administrator' },
    { value: 'windows_system_administrator', label: 'Windows System Administrator' },
    { value: 'voip_engineer', label: 'VoIP Engineer' },
    { value: 'mainframe_developer', label: 'Mainframe Developer' }
  ];
  