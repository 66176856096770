import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/components/metrics.scss';

const Metrics: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (!user) {
      navigate('/auth/login');
    }
  }, [navigate]);

  return (
    <div className="metrics">
      <div className="metrics-content">
        <h1>Monitor health of your job search</h1>
        <div className="stats">
          <div className="stat">
            <h2>Views</h2>
            <p>31</p>
            <span>+3 last day</span>
          </div>
          <div className="stat">
            <h2>Applications</h2>
            <p>63</p>
            <span>+1 last day</span>
          </div>
          <div className="stat">
            <h2>Interviews</h2>
            <p>10</p>
            <span>+1 last day</span>
          </div>
          <div className="stat">
            <h2>Total Profit</h2>
            <p>$628.00</p>
            <span>+10% this month</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
