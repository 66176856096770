import React from "react";
import { FaChevronDown } from "react-icons/fa";
import AiIcon from "../assets/ai-icon.svg";
import '../styles/components/suggestions.scss';
import "@radix-ui/themes/styles.css";
import { Accordion, AccordionContent, AccordionTrigger, AccordionItem } from "@radix-ui/react-accordion";
import { BulletPointSuggestion } from "./ResumeComponent";
import { IoChevronDownSharp } from "react-icons/io5"; // More modern arrow

const truncateText = (text: string) => {
  const maxChars = 65; // Adjust this number based on testing
  if (text.length <= maxChars) return text;
  return text.slice(0, maxChars) + '...';
};

interface SuggestionsProps {
  suggestions: BulletPointSuggestion[];
  expandedIndex: string | null;
  handleToggleDropdown: (id: string) => void;
  isHovered: boolean;
  handleMouseEnter: () => void;
  handleMouseLeave: () => void;
  handleSingleSuggestion: (suggestion: string, id: string) => void;
  score: number | null;
  isFloatingScore?: boolean;
  handleTailorButtonClick: () => void;
  isResumeTailored?: boolean;
  handleDownload: () => void;
  setSuggestions: (suggestions: BulletPointSuggestion[]) => void;
  loading: boolean;
  errorMessage: string | null;
  isInExpandedSidebar?: boolean; // Add this new prop
  setExpandedBulletPoint: (point: { expIndex: number, descId: string } | null) => void;
}

const Suggestions: React.FC<SuggestionsProps> = ({
  suggestions,
  expandedIndex,
  handleToggleDropdown,
  isHovered,
  handleMouseEnter,
  handleMouseLeave,
  handleTailorButtonClick,
  handleSingleSuggestion,
  score,
  isFloatingScore = false,
  isResumeTailored = false,
  handleDownload,
  setSuggestions,
  loading,
  errorMessage,
  isInExpandedSidebar = false, // Add this new prop
  setExpandedBulletPoint,
}) => {

  const handleDismissSuggestion = (suggestionId: string) => {
    const updatedSuggestions = suggestions.filter((item) => item.id !== suggestionId);
    setSuggestions(updatedSuggestions);
  };

  const handleAccordionChange = (value: string) => {
    // When accordion changes, find the corresponding suggestion
    const expandedSuggestion = suggestions.find(item => `item-${item.id}` === value);
    
    if (expandedSuggestion) {
      setExpandedBulletPoint({ 
        expIndex: expandedSuggestion.expIndex, // Keep the expIndex from the suggestion
        descId: expandedSuggestion.id // This is the UUID we'll use for matching
      });
    } else {
      setExpandedBulletPoint(null);
    }
    
    handleToggleDropdown(value);
  };

  return (
    <>
      <div className={`suggestions-container ${!isInExpandedSidebar ? 'full-screen-mode' : ''}`}>
        {loading ? (
          <div className="loading-state">
            <h3 className="suggestions-title">
              <img src={AiIcon} alt="AI Icon" className="ai-icon" />
              Suggestions
              <div className="progress-circle">
                <div className="loading-circle">
                  <svg height={70} width={70}>
                    <circle
                      stroke="#CCCCCC"
                      fill="transparent"
                      strokeWidth={4}
                      r={31}
                      cx={35}
                      cy={35}
                    />
                  </svg>
                  <div className="loading-dots">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </div>
                </div>
              </div>
            </h3>
            <div className="skeleton-suggestions">
              {Array.from({ length: 5 }).map((_, index) => (
                <div key={index} className="skeleton-suggestion">
                  <div className="skeleton-line long"></div>
                  <div className="skeleton-line short"></div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {isFloatingScore ? (
              <div className="floating-score-container">
                {score === null ? (
                  <div className="loading-circle">
                    <svg height={70} width={70}>
                      <circle
                        stroke="#CCCCCC"
                        fill="transparent"
                        strokeWidth={4}
                        r={31}
                        cx={35}
                        cy={35}
                      />
                    </svg>
                    <div className="loading-dots">
                      <span className="dot" />
                      <span className="dot" />
                      <span className="dot" />
                    </div>
                  </div>
                ) : (
                  <svg height={70} width={70}>
                    <defs>
                      <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
                        <stop offset="0%" stopColor="#43CC48" />
                        <stop offset="100%" stopColor="#216624" />
                      </linearGradient>
                    </defs>
                    <g style={{ transform: "rotate(90deg)", transformOrigin: "50% 50%" }}>
                      <circle
                        stroke="url(#gradient)"
                        fill="transparent"
                        strokeWidth={4}
                        strokeDasharray="157 157"
                        strokeDashoffset={157 - (score / 100) * 157}
                        r={31}
                        cx={35}
                        cy={35}
                        style={{ transition: "stroke-dashoffset 0.35s" }}
                        strokeLinecap="round"
                      />
                    </g>
                    <text
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dy=".3em"
                      fontSize="25px"
                      fontWeight="bold"
                      fill="black"
                    >
                      {score}
                    </text>
                  </svg>
                )}
              </div>
            ) : (
              <>
                <h3 className="suggestions-title">
                  <img src={AiIcon} alt="AI Icon" className="ai-icon" />
                  Suggestions
                  <div className="progress-circle">
                    {score === null ? (
                      <div className="loading-circle">
                        <svg height={70} width={70}>
                          <circle
                            stroke="#CCCCCC"
                            fill="transparent"
                            strokeWidth={4}
                            r={31}
                            cx={35}
                            cy={35}
                          />
                        </svg>
                        <div className="loading-dots">
                          <span className="dot" />
                          <span className="dot" />
                          <span className="dot" />
                        </div>
                      </div>
                    ) : (
                      <svg height={70} width={70}>
                        <defs>
                          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
                            <stop offset="0%" stopColor="#43CC48" />
                            <stop offset="100%" stopColor="#216624" />
                          </linearGradient>
                        </defs>
                        <g style={{ transform: "rotate(90deg)", transformOrigin: "50% 50%" }}>
                          <circle
                            stroke="url(#gradient)"
                            fill="transparent"
                            strokeWidth={4}
                            strokeDasharray="157 157"
                            strokeDashoffset={157 - (score / 100) * 157}
                            r={31}
                            cx={35}
                            cy={35}
                            style={{ transition: "stroke-dashoffset 0.35s" }}
                            strokeLinecap="round"
                          />
                        </g>
                        <text
                          x="50%"
                          y="50%"
                          textAnchor="middle"
                          dy=".3em"
                          fontSize="25px"
                          fontWeight="bold"
                          fill="black"
                        >
                          {score}
                        </text>
                      </svg>
                    )}
                  </div>
                </h3>

                <div className="suggestions-scroll">
                  <Accordion 
                    type="single" 
                    collapsible 
                    className="AccordionRoot"
                    onValueChange={handleAccordionChange}
                  >
                    {errorMessage ? (
                      <div className="error-message">{errorMessage}</div>
                    ) : suggestions && suggestions.length > 0 ? (
                      suggestions.map((item) => (
                        <AccordionItem 
                          key={item.id} 
                          value={`item-${item.id}`} 
                          className="AccordionItem accordion-item"
                        >
                          <AccordionTrigger 
                            className="accordion-trigger"
                            onClick={() => handleToggleDropdown(`item-${item.id}`)}
                          >
                            <div className="suggestion-text">
                              {item.suggested} {/* Remove the conditional truncation */}
                            </div>
                            <IoChevronDownSharp 
                              className={`dropdown-icon ${expandedIndex === `item-${item.id}` ? 'open' : ''}`}
                              size={18}
                            />
                          </AccordionTrigger>
                          <AccordionContent className="accordion-content">
                            <div className="button-container">
                              <button
                                className="suggestion-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleSingleSuggestion(item.suggested, item.id);
                                }}
                                disabled={loading}
                              >
                                Approve
                              </button>
                              <button
                                className="suggestion-secondary-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDismissSuggestion(item.id);
                                }}
                              >
                                Dismiss
                              </button>
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      ))
                    ) : (
                      // If no suggestions, render empty placeholders to maintain layout
                      Array.from({ length: 3 }).map((_, index) => (
                        <AccordionItem key={index} value={`item-placeholder-${index}`} className="AccordionItem accordion-item placeholder">
                          <AccordionTrigger className="accordion-trigger">
                            <div className="placeholder-text no-suggestions" />
                            <FaChevronDown className="dropdown-icon" />
                          </AccordionTrigger>
                        </AccordionItem>
                      ))
                    )}
                  </Accordion>
                </div>
              </>
            )}
          </>
        )}
      </div>
      
      <div className="action-buttons-grid">
        <button className="action-btn primary" onClick={handleDownload}>
          Download PDF
        </button>
        <button className="action-btn secondary" onClick={handleTailorButtonClick}>
          Tailor Cover Letter
        </button>
      </div>
    </>
  );
};

export default Suggestions;
