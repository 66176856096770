import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import "../../styles/components/landing-pages/pricing.scss";

const Pricing = () => {
  const [billingCycle, setBillingCycle] = useState<'weekly' | 'monthly'>('monthly');

  const pricingData = {
    free: {
      weekly: 0,
      monthly: 0
    },
    pro: {
      weekly: 8.99,
      monthly: 29.99
    }
  };

  return (
    <>
      <Navbar />
      <div className="pricing-page-second">
        <div className="pricing-container">
          <h1>Make every application count</h1>
          <p className="description">From smart job matching to AI-powered applications - tools that get you noticed</p>

          <div className="billing-toggle">
            <button 
              className={`toggle-btn ${billingCycle === 'weekly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('weekly')}
            >
              Weekly
            </button>
            <button 
              className={`toggle-btn ${billingCycle === 'monthly' ? 'active' : ''}`}
              onClick={() => setBillingCycle('monthly')}
            >
              Monthly
            </button>
          </div>

          <div className="pricing-cards">
            {/* Free Plan */}
            <div className="pricing-card">
              <h2 className="pricing-tier-title">Free</h2>
              <p className="description">Start your job search journey with essential tools and features.</p>
              <div className="price">
                <span className="amount">${pricingData.free[billingCycle]}</span>
                <span className="period">/{billingCycle === 'weekly' ? 'week' : 'month'}</span>
              </div>
              <Link to="/auth/signup">
                <button className="signup-btn">Sign up</button>
              </Link>
              <ul className="features">
                <li>
                  <span className="check">✓</span>
                  Tailored Job Matches
                </li>
                <li>
                  <span className="check">✓</span>
                  Autofill Applications Extension 
                </li>
                <li>
                  <span className="check">✓</span>
                  Shareable Portfolio
                </li>
              </ul>
            </div>

            {/* Pro Plan */}
            <div className="pricing-card pro">
              <h2 className="pricing-tier-title">Pro</h2>
              <p className="description">Unlock AI-powered tools to maximize your job search success.</p>
              <div className="price">
                <span className="amount">${pricingData.pro[billingCycle]}</span>
                <span className="period">/{billingCycle === 'weekly' ? 'week' : 'month'}</span>
              </div>
              <button className="signup-btn">Get started</button>
              <ul className="features">
                <li>
                  <span className="check">✓</span>
                  Everything in Free
                </li>
                <li>
                  <span className="check">✓</span>
                  AI Resume Tailoring
                </li>
                <li>
                  <span className="check">✓</span>
                  AI Cover Letter Generation
                </li>
                <li>
                  <span className="check">✓</span>
                  AI Mock Interviews
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
