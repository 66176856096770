import React from 'react';
import { FaChevronRight } from 'react-icons/fa6';  // Importing the modern arrow icon
import '../styles/components/jobTile.scss';

interface JobTileProps {
  job: any; // Use 'any' or define a JobData interface
  userId: any;
  handleSelection: (data: any) => void;
}

const JobTile: React.FC<JobTileProps> = ({ job, userId, handleSelection }) => {
  const { _id, title, company, location, salary, jobType, days_to_live } = job;

  // Logic to generate the company logo path dynamically based on the company name
  const getCompanyLogo = (company: string | undefined) => {
    if (!company) {
      return '/company-logos/default-logo.png'; // Fallback image if the company is undefined
    }
    const logoPath = `/company-logos/${company.toLowerCase().replace(/\s/g, '-')}.png`;
    return logoPath;
  };

  // Generate the company logo path
  const logoPath = getCompanyLogo(company);

  // Function to map job types to corresponding class names and format correctly
  const getJobTypeClass = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case 'full time':
        return 'full-time';
      case 'part time':
        return 'part-time';
      case 'internship':
        return 'internship';
      case 'contract':
        return 'contract';
      default:
        return '';
    }
  };

  // Correct job type text display
  const formatJobType = (jobType: string) => {
    switch (jobType.toLowerCase()) {
      case 'full time':
        return 'FULL-TIME';
      case 'part time':
        return 'PART-TIME';
      default:
        return jobType.toUpperCase();  // Default to uppercase for consistency
    }
  };

  // Get job type class name for dynamic styling
  const jobTypeClass = getJobTypeClass(jobType);

  // Add this new function to determine status class
  const getStatusClass = (daysToLive: number) => {
    if (daysToLive <= 5) return 'old';
    if (daysToLive <= 10) return 'moderate';
    return 'recent';  // for daysToLive 11-15
  };

  return (
    <div className="job-tile">
      {/* Add the status dot here, before the job type tag */}
      <div className={`status-dot ${getStatusClass(days_to_live)}`} />
      
      {/* Existing job type tag */}
      {jobType && <div className={`job-type-tag ${jobTypeClass}`}>{formatJobType(jobType)}</div>}

      <div className="job-info">
        <div className="company-logo-container">
          {/* Dynamically set the logo path */}
          <img src={logoPath} alt={company || 'Company'} className="company-logo" />
        </div>

        <h3 className="job-title" title={title}>
          {title}
        </h3>
        <p className="company-location">
          {company} • {location}
        </p>

        {/* Commented out the salary line but preserving the space */}
        {/* <p className="salary-range">{salary || 'Salary not specified'}</p> */}
        <div className="salary-placeholder" /> {/* Empty div to maintain spacing */}

        <div className="job-actions">
          <button onClick={() => handleSelection(job)} className="apply-button">Apply</button>
          {/* Commenting out View Details button since Apply now has the same functionality
          <button onClick={() => handleSelection(job)} className="view-details">
            View Details<span className="arrow"><FaChevronRight /></span>
          </button>
          */}
        </div>
      </div>
    </div>
  );
};

export default JobTile;
