import React, { useState, useEffect } from "react";
import "../../styles/components/landing-pages/navbar.scss";
import { HiMiniBars3 } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import logoImage from "../../assets/logo.svg";
import { IoClose } from "react-icons/io5";

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleToggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    
    if (newIsOpen) {
      // Lock scroll when menu opens
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      // Restore scroll when menu closes
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }
  };

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      document.documentElement.style.overflow = '';
      document.body.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, []);

  const handleJobsClick = () => {
    navigate('/jobs');
  };

  return (
    <nav className={`navbar ${scrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <div className="navbar-left">
          <Link to="/">
            <img src={logoImage} alt="Company Logo" className="logo" />
          </Link>
          <div className={`nav-menu ${isOpen ? "active" : ""}`}>
            <ul className={`nav-list ${isOpen ? "active" : ""}`}>
              <li className="nav-item">
                <Link to="/jobs" className="nav-link" onClick={handleJobsClick}>
                  Jobs
                </Link>
              </li>
              <li className="nav-item">
                <a href="#quickapply" className="nav-link">
                  QuickApply
                </a>
              </li>
              <li className="nav-item">
                <a href="#resume-builder" className="nav-link">
                  Resume Builder
                </a>
              </li>
              <li className="nav-item">
                <Link to="/pricing" className="nav-link">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <a href="#more" className="nav-link">
                  More
                </a>
              </li>
            </ul>
            {/* Move auth links outside of nav-list */}
            <div className="mobile-auth-link">
              <Link to="/auth/login" className="login-link">
                Login
              </Link>
              <Link to="/auth/signup" className="get-started">
                Get Started
              </Link>
            </div>
          </div>
        </div>
        {/* Only show Login and Get Started on larger screens */}
        <div className="navbar-right">
          <Link to="/auth/login" className="login-link">
            Login
          </Link>
          <Link to="/auth/signup" className="get-started">
            Get Started
          </Link>
        </div>
        <div className="menu-icon" onClick={handleToggle}>
          {isOpen ? <IoClose /> : <HiMiniBars3 />}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
