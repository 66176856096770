import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import JobTile from './JobTile';
import { FaAngleDown, FaAngleUp, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import jobTitles from '../jobs_titles.json';
import Sidebar from './Sidebar';
import '../styles/components/jobSearch.scss';
import { fetchJobs } from '../services/jobService';
import ReactPaginate from 'react-paginate';
import { User } from 'firebase/auth';
import JobDetailsModal, { JobData } from './JobDetailsModal';

// Define types for better type safety
interface JobHub {
  value: string;
  label: string;
}

interface JobType {
  value: string;
  label: string;
}

interface Job {
  _id: string;
  title: string;
  location: string;
  jobType: string;
  industry: string;
  [key: string]: any;
}

const defaultPreferences = {
  locations: ['open_to_all'],
  jobType: ['full_time'],
  industries: Object.keys(jobTitles).map((key) => ({ value: key, label: key })),
};

const JobSearch: React.FC = () => {
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState<string[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [jobResults, setJobResults] = useState<Job[]>([]);
  const [error, setError] = useState('');
  const [preferencesSaved, setPreferencesSaved] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({});
  const [scrolled, setScrolled] = useState(false);
  const dropdownRefs = useRef<{ [key: string]: any }>({});
  const navigate = useNavigate();
  const [user, setUser] = useState<any>();
  const [selection, setSelection] = useState<JobData | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 54;
  const [availableJobHubs, setAvailableJobHubs] = useState<JobHub[]>([]);
  const [availableJobTypes, setAvailableJobTypes] = useState<JobType[]>([]);
  const [availableCategories, setAvailableCategories] = useState<JobType[]>([])

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = localStorage.getItem('user');
        if (userData) {
          const user = JSON.parse(userData);
          if (user._id) {
            setUser(user);
          } else {
            navigate('/auth/login');
          }
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to fetch user data. Please check your permissions.');
      }
    };

    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const jobs = await fetchJobs();
        setJobResults(jobs);

        const uniqueLocations = Array.from(new Set(jobs.map((job:any) => job.location)))
          .filter(Boolean)
          .map((location: any) => ({
            value: location.toLowerCase().replace(/\s+/g, '_'),
            label: location
          }));
        setAvailableJobHubs([
          ...uniqueLocations,
          { value: 'open_to_all', label: 'Open to All Locations' }
        ]);

        const uniqueJobTypes = Array.from(new Set(jobs.map((job: any) => job.jobType)))
          .filter(Boolean)
          .map((type:any )=> ({
            value: type.toLowerCase(),
            label: type
          }));
        setAvailableJobTypes(uniqueJobTypes);

        const uniqueCategory = Array.from(new Set(jobs.map((job: any) => job.title)))
        .filter(Boolean)
          .map((type:any )=> ({
            value: type.toLowerCase(),
            label: type
          }));
        setAvailableCategories(uniqueCategory)
      } catch (error) {
        console.error('Error fetching jobs:', error);
        setError('Failed to fetch jobs.');
      }
    };

    loadJobs();
  }, [preferencesSaved]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRefs.current) return;
      
      const clickedOutside = Object.values(dropdownRefs.current).every(
        (ref) => !ref?.contains(event.target as Node)
      );
      
      if (clickedOutside) {
        setDropdownOpen({});
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    key: string
  ) => {
    const { value, checked } = event.target;
    const currentUser = auth.currentUser;

    setter((prev) => {
      const updated = checked 
        ? [...prev, value]
        : prev.filter((option) => option !== value);
      
      updatePreferences(currentUser, key, updated);
      return updated;
    });
  };

  const updatePreferences = async (currentUser: User | null, key: string, updated: string[]) => {
    if (!currentUser) return;

    try {
      const preferences = {
        ...defaultPreferences,
        [key]: updated.length > 0 ? updated : defaultPreferences[key as keyof typeof defaultPreferences],
        locations: key === 'locations' ? updated : selectedLocations,
        jobTypes: key === 'jobTypes' ? updated : selectedJobTypes,
        industries: key === 'industries' ? updated : selectedIndustries,
      };

      await setDoc(
        doc(db, 'users', currentUser.uid),
        { preferences },
        { merge: true }
      );
      
      setPreferencesSaved(true);
    } catch (error) {
      console.error('Error saving preferences:', error);
      setError('Failed to save preferences.');
    }
  };

  const clearFilters = () => {
    setSelectedLocations([]);
    setSelectedJobTypes([]);
    setSelectedIndustries([]);
    
    const currentUser = auth.currentUser;
    if (currentUser) {
      updatePreferences(currentUser, 'locations', []);
      updatePreferences(currentUser, 'jobTypes', []);
      updatePreferences(currentUser, 'industries', []);
    }
  };

  const toggleDropdown = (key: string) => {
    setDropdownOpen((prev) => ({
      ...Object.keys(prev).reduce((acc, k) => ({ ...acc, [k]: false }), {}),
      [key]: !prev[key],
    }));
  };

  const renderCheckboxList = (
    options: { value: string; label: string }[],
    selected: string[],
    setter: React.Dispatch<React.SetStateAction<string[]>>,
    placeholder: string,
    key: string
  ) => (
    <div 
      className={`dropdown ${selected.length > 0 ? 'active' : ''}`}
      ref={(el) => (dropdownRefs.current[key] = el)}
    >
      <button 
        type="button" 
        className="dropdown-button" 
        onClick={() => toggleDropdown(key)}
      >
        {placeholder} {selected.length > 0 && `(${selected.length})`}
        {dropdownOpen[key] ? <FaAngleUp /> : <FaAngleDown />}
      </button>
      
      {dropdownOpen[key] && (
        <div className="dropdown-content open">
          {options.map((option) => (
            <div
              key={option.value}
              className="checkbox-item"
              onClick={() => {
                const newChecked = !selected.includes(option.value);
                handleCheckboxChange(
                  {
                    target: { 
                      value: option.value, 
                      checked: newChecked 
                    }
                  } as React.ChangeEvent<HTMLInputElement>,
                  setter,
                  key
                );
              }}
            >
              <input
                type="checkbox"
                value={option.value}
                checked={selected.includes(option.value)}
                onChange={(e) => handleCheckboxChange(e, setter, key)}
              />
              <label>{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const filterJobs = useCallback((jobs: Job[]) => {
    return jobs.filter((job) => {
      // Location filtering
      const locationMatch = 
        selectedLocations.length === 0 ||
        selectedLocations.includes('open_to_all') ||
        selectedLocations.some((location) => {
          const normalizedJobLocation = job.location?.toLowerCase().trim();
          const normalizedFilter = location.toLowerCase().replace(/_/g, ' ').trim();
          return normalizedJobLocation?.includes(normalizedFilter);
        });

      // Job type filtering
      const jobTypeMatch =
        selectedJobTypes.length === 0 ||
        selectedJobTypes.some((type) => {
          const normalizedJobType = job.jobType?.toLowerCase().trim();
          const normalizedFilter = type.toLowerCase().trim();
          return normalizedJobType === normalizedFilter;
        });

      // Industry/Category filtering
      const industryMatch =
        selectedIndustries.length === 0 ||
        selectedIndustries.some((industry) => {
          const normalizedJobTitle = job.title?.toLowerCase().trim();
          const normalizedFilter = industry.toLowerCase().trim();
          return normalizedJobTitle?.includes(normalizedFilter);
        });

      return locationMatch && jobTypeMatch && industryMatch;
    });
  }, [selectedLocations, selectedJobTypes, selectedIndustries]);

  const filteredJobs = filterJobs(jobResults);
  const pageCount = Math.ceil(filteredJobs.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentJobs = filteredJobs.slice(offset, offset + itemsPerPage);

  const handlePageClick = (data: { selected: number }) => {
    setCurrentPage(data.selected);
    window.scrollTo({ top: 0, behavior: 'auto' });
  };

  const handleSelection = useCallback((job: JobData) => {
    setSelection(job);
  }, []);

  return (
    <div className="job-search-container">
      <Sidebar />
      <div className="job-search">
        <div className={`filter-container ${scrolled ? 'scrolled' : ''}`}>
          <div className="filter-content">
            {renderCheckboxList(
              availableJobHubs,
              selectedLocations,
              setSelectedLocations,
              'Location',
              'locations'
            )}
            {renderCheckboxList(
              availableJobTypes,
              selectedJobTypes,
              setSelectedJobTypes,
              'Job Type',
              'jobTypes'
            )}
            {renderCheckboxList(
              availableCategories,
              selectedIndustries,
              setSelectedIndustries,
              'Category',
              'industries'
            )}
            <span className="clear-filters" onClick={clearFilters}>
              Clear Filters
            </span>
          </div>
        </div>

        <div className="job-results job-tiles-container">
          {currentJobs.length > 0 ? (
            currentJobs.map((job, index) => (
              <JobTile
                key={`${job._id}-${index}`}
                job={job}
                handleSelection={handleSelection}
                userId={user?._id}
              />
            ))
          ) : (
            <p>No jobs found matching the selected filters.</p>
          )}
        </div>

        {filteredJobs.length > itemsPerPage && (
          <div className="pagination-container">
            <ReactPaginate
              previousLabel={<FaAngleLeft />}
              nextLabel={<FaAngleRight />}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
              previousClassName="prev"
              nextClassName="next"
              disabledClassName="disabled"
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              pageLinkClassName="page-link"
            />
          </div>
        )}
      </div>

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default JobSearch;