import React, { useEffect, useState } from 'react';
import { fetchJobs } from '../services/jobService'; // Import the job fetching function
import JobTile from './JobTile';  // Reuse the JobTile component
import '../styles/components/tracker.scss';  // Separate SCSS for tracker
import JobDetailsModal, { JobData } from './JobDetailsModal';

const Tracker: React.FC = () => {
  const [trackerJobs, setTrackerJobs] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [user, setUser] = useState<any>(null);
  const [selection, setSelection] = useState<JobData | null>(null);

  // Function to fetch jobs
  const fetchJobListings = async () => {
    try {
      const jobs = await fetchJobs();  // Assuming the same service as Dashboard
      setTrackerJobs(jobs.slice(0, 6));  // Fetch only the first 6 jobs
    } catch (err) {
      setError('Failed to fetch jobs.');
    }
  };

  useEffect(() => {
    fetchJobListings();  // Fetch jobs on component mount

    // Fetch user data from local storage
    const storedUser = JSON.parse(localStorage.getItem('user') || '{}');
    if (storedUser && storedUser._id) {
      setUser(storedUser);
    }
  }, []);

  const handleJobSelection = (job: JobData) => {
    setSelection(job);
  };

  return (
    <div className="job-tiles-container">
      {trackerJobs.length > 0 ? (
        trackerJobs.map((job, index) => (
          <JobTile 
            key={index} 
            job={job} 
            userId={user?._id} 
            handleSelection={handleJobSelection} 
          />
        ))
      ) : (
        <p>No jobs found.</p>
      )}

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default Tracker;
