import React, { useCallback, useEffect, useState } from 'react';
import axiosClient from '../axios-client';
import '../styles/components/coverLetter.scss'; // Import the SCSS file
import { ResumeText } from './ResumeComponent';  // Import the shared interface
import { ReactComponent as AIIcon } from '../assets/ai-icon.svg'; // Update the import to use the correct SVG
import { mainUrl } from '../constant';
import axios from 'axios';

interface JobData {
  _id: string;
  title: string;
  original_title?: string;
  company: string;
  location: string;
  location_hub?: string;
  summary?: string;
  qualifications?: string[];
  preferredQualifications?: string[];
  responsibilities?: string[];
  environment?: string;
  skills?: string | string[]; // Allow skills to be a string or an array
  salary?: string;
  experience_level?: string;
  jobType?: string;
  tailored_flag?: string;
  days_to_live?: number;
  job_url?: string;
}

interface CoverLetterProps {
  job: JobData;
  resumeText: ResumeText;  // Use ResumeText instead of Resume
}

interface Selection {
  text: string;
  x: number;
  y: number;
}

const CoverLetter: React.FC<CoverLetterProps> = ({ job, resumeText }) => {
  const [coverLetter, setCoverLetter] = useState('');
  const [displayedText, setDisplayedText] = useState('');
  const [loading, setLoading] = useState(true);
  const [typingComplete, setTypingComplete] = useState(false);
  const [selection, setSelection] = useState<Selection | null>(null);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  // This useEffect handles the typing animation
  useEffect(() => {
    if (coverLetter && !loading) {
      let currentIndex = 0;
      setDisplayedText(coverLetter[0]); // Start with first character

      const typingInterval = setInterval(() => {
        currentIndex++;
        setDisplayedText((prev) => prev + coverLetter[currentIndex]);

        if (currentIndex === coverLetter.length - 1) {
          clearInterval(typingInterval);
          setTypingComplete(true);
          setShowButtons(true);
        }
      }, 5); // 5ms typing speed

      return () => clearInterval(typingInterval);
    }
  }, [coverLetter, loading]);

  // This useEffect triggers the API call
  useEffect(() => {
    fetchCoverLetter();
  }, [job]); // Trigger when job changes

  const fetchCoverLetter = async () => {
    try {
      setLoading(true);
      const description = `title: ${job.original_title || job.title} 
        responsibilities: ${job.responsibilities?.join(' ')} 
        qualification: ${job.qualifications?.join(' ')} 
        skills: ${Array.isArray(job.skills) ? job.skills.join(' ') : job.skills}`;

      const response = await axiosClient.post('builder/cover-letter', {
        jobDescription: description,
        userDetails: resumeText,
      });

      const data = response.data;
      setCoverLetter(data || '');
      setLoading(false);
      setTypingComplete(false);
    } catch (error) {
      console.error('Error fetching cover letter:', error);
      setLoading(false);
      setTypingComplete(false);
      setCoverLetter('Failed to generate cover letter. Please try again.');
    }
  };

  const handleTextSelection = useCallback(() => {
    const selectedText = window.getSelection()?.toString().trim();
    if (selectedText) {
      const range = window.getSelection()?.getRangeAt(0);
      const rect = range?.getBoundingClientRect();
      setSelection({
        text: selectedText,
        x: rect?.left || 0,
        y: rect?.bottom || 0,
      });
      console.log(selection);
    } else {
      setSelection(null);
      setShowSuggestions(false);
    }
  }, []);

  const handleRephrase = async () => {
    if (selection) {
      try {
        const response = await axiosClient.post('builder/rephrase', {
          text: selection.text,
          context: coverLetter
        });

        setSuggestions(response.data.suggestions || []);
        setShowSuggestions(true);
      } catch (error) {
        console.error('Error getting rephrasing suggestions:', error);
      }
    }
  };

  const handleDownload = async () => {
    const user = JSON.parse(String(localStorage.getItem("user")));
    if (coverLetter) {
      try {
        const response = await axiosClient.post('/pdf/generate-coverletter-pdf', { 'coverLetter': coverLetter });
        if (response.data && response.data.downloadUrl) {
          window.open(`${mainUrl}${response.data.downloadUrl}`, '_blank');

          const link = document.createElement('a');
          link.href = response.data.downloadUrl;
          link.download = `${user.firstName}_${user.lastName}_coverletter.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          
          setShowButtons(true);
        } else {
          throw new Error('Download URL not received from server');
        }
      } catch (error: any) {
        console.error('Error generating PDF:', error);
        if (axios.isAxiosError(error) && error.response) {
          console.error('Error response:', error.response.data);
          alert(`Error: ${error.response.data.message || 'Unknown error'}`);
        } else {
          alert('An error occurred while generating the PDF. Please try again.');
        }
      }
    }
  }

  const HoveringButton = () => (
    <button
      className="tailor-ai-button"
      style={{ position: 'absolute', left: `${selection?.x}px`, top: `${selection?.y}px` }}
      onClick={handleRephrase}
    >
      <AIIcon
        className="ai-icon"
        style={{ filter: 'invert(58%) sepia(7%) saturate(98%) hue-rotate(314deg) brightness(89%) contrast(86%)' }}
      />
      <span className="btn-text">Tailor with AI</span>
    </button>
  );

  const SuggestionsCard = () => (
    <div
      className="suggestions-card"
      style={{ position: 'absolute', left: `${selection?.x}px`, top: `${selection?.y ?? 0 + 30}px` }}
    >
      {suggestions.map((suggestion, index) => (
        <div key={index} className="suggestion">{suggestion}</div>
      ))}
    </div>
  );

  const handleEdit = (newContent: string) => {
    setCoverLetter(newContent);
  };

  return (
    <div className="resume-container">
      <div className="left-container">
        <div className={`cover-letter-container ${loading ? 'loading' : ''}`} onMouseUp={handleTextSelection}>
          <div className="cover-letter-content">
            <div className="content">
              {loading ? (
                <div className="loading-placeholder">
                  <div className="placeholder-line short"></div>
                  <div className="placeholder-line medium"></div>
                  <div className="placeholder-line long"></div>
                  <div className="placeholder-line"></div>
                  <div className="placeholder-line medium"></div>
                  <div className="placeholder-line short"></div>
                  <div className="placeholder-line"></div>
                </div>
              ) : (
                <div
                  contentEditable={typingComplete}
                  onBlur={(e) => handleEdit(e.currentTarget.innerText)}
                  suppressContentEditableWarning={true}
                  className="editable-content"
                >
                  {displayedText || 'No cover letter available.'}
                </div>
              )}
            </div>
          </div>
          {selection && <HoveringButton />}
          {showSuggestions && <SuggestionsCard />}
        </div>
      </div>
      <div className="right-container relative">
        <div className='bottom-0 absolute w-11/12 flex flex-col gap-3'>
          {showButtons && (
            <>
              <a 
                href={job.job_url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className='external-link'
              >
                Apply Externally<span className="arrow">↗</span>
              </a>
              <button className='action-btn primary' onClick={handleDownload}>
                Download PDF
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CoverLetter;
