import React, { useState, useEffect } from 'react';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import jobTitles from '../jobs_titles.json';
import resumeUploadGraphic from '../resume-upload.png';
import Sidebar from './Sidebar';
import '../styles/components/preferences.scss';

const jobHubs = [
  { value: 'san_francisco_bay_area', label: 'San Francisco Bay Area' },
  { value: 'greater_seattle_area', label: 'Greater Seattle Area' },
  { value: 'new_york_metropolitan_area', label: 'New York Metropolitan Area' },
  { value: 'greater_los_angeles_area', label: 'Greater Los Angeles Area' },
  { value: 'boston_metropolitan_area', label: 'Boston Metropolitan Area' },
  { value: 'chicago_metropolitan_area', label: 'Chicago Metropolitan Area' },
  { value: 'washington_dc_metro_area', label: 'Washington D.C. Metro Area' },
  { value: 'austin_metropolitan_area', label: 'Austin Metropolitan Area' },
  { value: 'dallas_fort_worth_metroplex', label: 'Dallas-Fort Worth Metroplex' },
  { value: 'atlanta_metropolitan_area', label: 'Atlanta Metropolitan Area' },
  { value: 'denver_metropolitan_area', label: 'Denver Metropolitan Area' },
  { value: 'phoenix_metropolitan_area', label: 'Phoenix Metropolitan Area' },
  { value: 'open_to_all', label: 'Open to All Locations' },
];

const workEnvironments = [
  { value: 'remote', label: 'Remote' },
  { value: 'onsite', label: 'On-site' },
  { value: 'hybrid', label: 'Hybrid' },
];

interface PreferencesProps {
  onSave: (locations: any[], jobTypes: any[], industries: any[], resumeFile: File | null) => void;
}

const Preferences: React.FC<PreferencesProps> = ({ onSave }) => {
  const [resumeFile, setResumeFile] = useState<File | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [selectedIndustries, setSelectedIndustries] = useState<any[]>([]);
  const [selectedWorkEnvironment, setSelectedWorkEnvironment] = useState<any[]>([]);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const fetchPreferences = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const data = userDoc.data();
          if (data.preferences) {
            setSelectedLocations(data.preferences.locations || []);
            setSelectedIndustries(data.preferences.industries || []);
            setSelectedWorkEnvironment(data.preferences.workEnvironment || []);
          }
        }
      }
    };

    fetchPreferences();
  }, []);

  const handleSavePreferences = async () => {
    setLoading(true);
    setError('');
    const user = auth.currentUser;
    try {
      if (user) {
        await setDoc(doc(db, 'users', user.uid), {
          preferences: {
            locations: selectedLocations.length > 0 ? selectedLocations : ['open_to_all'],
            jobTypes: ['full_time'],
            industries: selectedIndustries.length > 0 ? selectedIndustries : Object.keys(jobTitles).map(key => ({ value: key, label: key })),
            workEnvironment: selectedWorkEnvironment,
            resumeFile: resumeFile ? resumeFile.name : null,
          }
        }, { merge: true });
        onSave(selectedLocations, ['full_time'], selectedIndustries, resumeFile); // Call onSave prop
        navigate('/job-search/jobs'); // Navigate to job search results page
      }
    } catch (error) {
      setError('Failed to save preferences. Please try again.');
      console.error('Error saving preferences:', error);
    } finally {
      setLoading(false);
    }
  };

  const onDrop = (acceptedFiles: File[], fileRejections: any[]) => {
    if (fileRejections.length > 0) {
      alert('Only PDF and DOCX files are supported.');
      return;
    }
    setResumeFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
    },
  });

  const handleOptionClick = (value: string, setter: React.Dispatch<React.SetStateAction<any[]>>, selected: any[]) => {
    if (selected.some(option => option.value === value)) {
      setter(prev => prev.filter(option => option.value !== value));
    } else {
      setter(prev => [...prev, { value }]);
    }
  };

  const renderOptions = (options: any[], selected: any[], setter: React.Dispatch<React.SetStateAction<any[]>>, key: string) => (
    <div className="options-container">
      {options.map(option => (
        <button
          key={option.value}
          className={`option ${selected.some(selectedOption => selectedOption.value === option.value) ? 'selected' : ''}`}
          onClick={() => handleOptionClick(option.value, setter, selected)}
        >
          {option.label}
        </button>
      ))}
    </div>
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="step-content welcome-screen">
            <h2>Let's Personalize Your Job Search!</h2>
            <p>We need a few details to help us tailor the best job recommendations for you.</p>
            <button onClick={() => setStep(2)} className="styled-button">Get Started</button>
          </div>
        );
      case 2:
        return (
          <div className="step-content consistent-title screen-2">
            <h2>Upload Your Resume</h2>
            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <div className="upload-instructions">
                <img src={resumeUploadGraphic} alt="Resume Upload Graphic" className="upload-image" />
                {resumeFile ? (
                  <div className="file-display">
                    <p>{resumeFile.name}</p>
                    <FaTimes className="remove-file" onClick={() => setResumeFile(null)} />
                  </div>
                ) : (
                  <>
                    <p className="bold-text">Select a file or drag and drop here</p>
                    <p>DOCX or PDF, file size no more than 10MB</p>
                    <button className="select-file-button">SELECT FILE</button>
                  </>
                )}
              </div>
            </div>
            <div className="navigation-buttons">
              <button onClick={() => setStep(1)} className="back-button">
                <FaChevronLeft className="back-arrow" /> Back
              </button>
              <button onClick={() => setStep(3)} className="next-button">
                Next <FaChevronRight className="next-arrow" />
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="step-content consistent-title screen-3">
            <h2>Location Preferences</h2>
            {renderOptions(jobHubs, selectedLocations, setSelectedLocations, "locations")}
            <div className="divider"></div> {/* Divider Line */}
            {renderOptions(workEnvironments, selectedWorkEnvironment, setSelectedWorkEnvironment, "work_environment")}
            <div className="navigation-buttons">
              <button onClick={() => setStep(2)} className="back-button">
                <FaChevronLeft className="back-arrow" /> Back
              </button>
              <button onClick={() => setStep(4)} className="next-button">
                Next <FaChevronRight className="next-arrow" />
              </button>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="step-content consistent-title screen-4">
            <h2>Preferred Industry</h2>
            {renderOptions(Object.keys(jobTitles).map(key => ({ value: key, label: key })), selectedIndustries, setSelectedIndustries, "industries")}
            <div className="navigation-buttons">
              <button onClick={() => setStep(3)} className="back-button">
                <FaChevronLeft className="back-arrow" /> Back
              </button>
              <button onClick={handleSavePreferences} className="confirm-button">
                {loading ? 'Saving...' : 'Confirm'} <FaChevronRight className="confirm-arrow" />
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="preferences-page">
      <Sidebar />
      <div className="preferences-content">
        <div className="progress-dots">
          <div
            className="progress-bar"
            style={{ width: `calc(${(step - 1) * 25}px + ${(step - 1) * 12}px + 12px)` }} // 25px gap + 12px dot diameter + additional 12px
          ></div>
          {[...Array(4)].map((_, index) => (
            <span key={index} className={index < step ? 'active' : ''}></span>
          ))}
        </div>
        {error && <p className="error-message">{error}</p>}
        <div className="onboarding-content">
          {renderStepContent()}
        </div>
      </div>
    </div>
  );
};

export default Preferences;
