import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import '../styles/components/onboarding.scss';
import ProgressBar from './ui/ProgressBar';
import { Bounce, toast } from 'react-toastify';
import axiosClient from '../axios-client';
import { useNavigate } from 'react-router-dom';
import { FileUploader } from './ui/FileUploader';
import ReactConfetti from 'react-confetti';
import { jobTitles, JobOption } from '../data/jobTitles';

const locationOptions = [
  { value: 'san_francisco_bay_area', label: '🌉 San Francisco Bay Area' },
  { value: 'greater_seattle_area', label: '🌲 Greater Seattle Area' },
  { value: 'new_york_metropolitan_area', label: '🗽 New York Metropolitan Area' },
  { value: 'greater_los_angeles_area', label: '🌴 Greater Los Angeles Area' },
  { value: 'boston_metropolitan_area', label: '🏙️ Boston Metropolitan Area' },
  { value: 'chicago_metropolitan_area', label: '🌬️ Chicago Metropolitan Area' },
  { value: 'washington_dc_metro_area', label: '🏛️ Washington D.C. Metro Area' },
  { value: 'austin_metropolitan_area', label: '🎸 Austin Metropolitan Area' },
  { value: 'dallas_fort_worth_metroplex', label: '🤠 Dallas-Fort Worth Metroplex' },
  { value: 'atlanta_metropolitan_area', label: '🍑 Atlanta Metropolitan Area' },
  { value: 'denver_metropolitan_area', label: '🏔️ Denver Metropolitan Area' },
  { value: 'phoenix_metropolitan_area', label: '🌵 Phoenix Metropolitan Area' },
  { value: 'open_to_all', label: '🌍 Open to All Locations' },
];

const industryOptions = [
  { value: 'Software Engineering', label: '💻 Software Engineering' },
  { value: 'Data Science', label: '📊 Data Science' },
  { value: 'Cybersecurity & IT', label: '🔒 Cybersecurity & IT' },
  { value: 'Product', label: '🛠️ Product' },
  { value: 'Marketing', label: '📣 Marketing' },
  { value: 'Sales', label: '💼 Sales' },
  { value: 'Finance', label: '💰 Finance' },
  { value: 'Consulting', label: '🧑‍💼 Consulting' },
  { value: 'Logistics & Operations', label: '📦 Logistics & Operations' },
  { value: 'UI/UX & Design', label: '🎨 UI/UX & Design' },
  { value: 'Mechanical Engineering', label: '⚙️ Mechanical Engineering' },
  { value: 'Electrical Engineering', label: '🔌 Electrical Engineering' },
  { value: 'Civil Engineering', label: '🏗️ Civil Engineering' },
  { value: 'Chemical Engineering', label: '⚗️ Chemical Engineering' },
  { value: 'Aerospace Engineering', label: '✈️ Aerospace Engineering' },
  { value: 'Robotics & Automation', label: '🤖 Robotics & Automation' },
  { value: 'Biomedical Engineering', label: '🧬 Biomedical Engineering' },
  { value: 'Accounting', label: '📈 Accounting' },
];

const Onboarding: React.FC = () => {
  const [step, setStep] = useState(1);
  const totalSteps = 7;
  const [fadeClass, setFadeClass] = useState('fade-in');
  const [loading, setLoading] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [selectedIndustries, setSelectedIndustries] = useState<string[]>([]);
  const [resumeData, setResumeData] = useState<any>();
  const navigate = useNavigate();
  const [jobRole, setJobRole] = useState('');
  const [applicationGoal, setApplicationGoal] = useState('');
  const [suggestedText, setSuggestedText] = useState('');
  const [matchingJob, setMatchingJob] = useState<JobOption | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [windowDimensions, setWindowDimensions] = useState({ 
    width: window.innerWidth,
    height: window.innerHeight 
  });

  const nextStep = () => {
    if (step < totalSteps) {
      setFadeClass('fade-out');
      setTimeout(() => {
        setStep(step + 1);
        setFadeClass('fade-in');
      }, 400);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setFadeClass('fade-out');
      setTimeout(() => {
        setStep(step - 1);
        setFadeClass('fade-in');
      }, 400);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const selectedFile = event.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile) {
      handleFileUpload(selectedFile);
    } else {
      setLoading(false);
    }
  };

  const handleFileUpload = async (file: File) => {
    if (!file) {
      showToast('Please select a file');
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append('resume', file);

    try {
      const response = await axiosClient.post('/builder/resume-parser', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setLoading(false);
      setResumeData(response.data);
      toast('Resume Uploaded Successfully', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      setLoading(false);
      toast('Failed to upload resume', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      console.error('Upload error:', error);
    }
  };

  const showToast = (message: string) => {
    toast(message, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
      transition: Bounce,
    });
  };

  const handleNavigationKeyDown = (e: globalThis.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === 'Space') {
      nextStep();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleNavigationKeyDown);
    return () => {
      window.removeEventListener('keydown', handleNavigationKeyDown);
    };
  }, [step]);

  const handleFinish = async () => {
    const user = JSON.parse(String(localStorage.getItem('user')));
    if (user) {
      await axiosClient.patch(`users/${user._id}`, {
        ...resumeData,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        userOnboarding: true,
        selectedLocations,
        selectedIndustries,
        jobRole,
        applicationGoal,
      });
      navigate('/dashboard');
    }
  };

  const toggleLocation = (value: string) => {
    if (value === 'open_to_all') {
      if (selectedLocations.includes('open_to_all')) {
        setSelectedLocations([]);
      } else {
        setSelectedLocations(locationOptions.map((option) => option.value));
      }
    } else {
      setSelectedLocations((prev) =>
        prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
      );
    }
  };

  const toggleIndustry = (industry: string) => {
    setSelectedIndustries((prev) =>
      prev.includes(industry) ? prev.filter((item) => item !== industry) : [...prev, industry]
    );
  };

  // Inline autocomplete functions
  const handleJobRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setJobRole(inputValue);

    if (inputValue === '') {
      setSuggestedText('');
      setMatchingJob(null);
      return;
    }

    const matching = jobTitles.find((job) =>
      job.label.toLowerCase().startsWith(inputValue.toLowerCase())
    );

    if (matching) {
      setSuggestedText(matching.label.slice(inputValue.length));
      setMatchingJob(matching);
    } else {
      setSuggestedText('');
      setMatchingJob(null);
    }
  };

  const handleJobRoleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ((e.key === 'Tab' || e.key === 'ArrowRight') && suggestedText !== '') {
      e.preventDefault();
      if (matchingJob) {
        setJobRole(matchingJob.label);
        setSuggestedText('');
        setMatchingJob(null);
      }
    }
  };

  const handleApplicationGoalChange = (e: ChangeEvent<HTMLInputElement>) => {
    setApplicationGoal(e.target.value);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="welcome-container">
            <h1>Welcome to Your Personalized&nbsp;Job&nbsp;Search</h1>
            <p>
              Let's customize your experience to connect you with the
              perfect&nbsp;opportunities.
            </p>
            <button className="start-button" onClick={nextStep}>
              Get Started
            </button>
          </div>
        );
      case 2:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            <h1>Upload Your Resume</h1>

            <FileUploader
              onFileSelect={handleFileChange}
              loading={loading}
              file={file}
              accept=".pdf,.docx"
            />

            <div className="next-wrapper">
              <button onClick={nextStep} disabled={loading} className="nav-button next-button">
                {loading ? (
                  <div className="loading-state">
                    <div className="spinner" />
                  </div>
                ) : (
                  'Next'
                )}
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
              <div className="ml-10">
                <button onClick={nextStep} disabled={loading}>
                  Skip
                </button>
              </div>
            </div>
          </div>
        );
      case 3:
      case 4:
      case 5:
      case 6:
        return (
          <div className="step-left-align">
            <button onClick={prevStep} className="nav-button back-button">
              <span className="back-arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="back-svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
              </span>
              Back
            </button>
            {step === 3 && (
              <>
                <h1>Preferred Location</h1>
                <div className="bubble-group">
                  {locationOptions.map((option) => (
                    <div
                      key={option.value}
                      className={`bubble ${
                        selectedLocations.includes(option.value) ? 'selected' : ''
                      }`}
                      onClick={() => toggleLocation(option.value)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </>
            )}
            {step === 4 && (
              <>
                <h1>Preferred Industry</h1>
                <div className="bubble-group">
                  {industryOptions.map((option) => (
                    <div
                      key={option.value}
                      className={`bubble ${
                        selectedIndustries.includes(option.value) ? 'selected' : ''
                      }`}
                      onClick={() => toggleIndustry(option.value)}
                    >
                      {option.label}
                    </div>
                  ))}
                </div>
              </>
            )}
            {step === 5 && (
              <>
                <h1>Desired Job Role</h1>
                <div className="ghost-input-wrapper">
                  <div className="ghost-input-container">
                    <input
                      type="text"
                      ref={inputRef}
                      placeholder="type here"
                      value={jobRole}
                      onChange={handleJobRoleChange}
                      onKeyDown={handleJobRoleKeyDown}
                      className="job-role-input"
                      autoFocus
                    />
                    <div className="ghost-text">
                      {jobRole}
                      <span className="suggested-text">{suggestedText}</span>
                    </div>
                  </div>
                </div>
                <p className="autocomplete-instruction">
                  Press <strong>Tab</strong> or <strong>→</strong> to accept the suggestion.
                </p>
              </>
            )}
            {step === 6 && (
              <>
                <h1>Weekly Application Goal</h1>
                <input
                  type="number"
                  placeholder="type a number"
                  autoFocus
                  spellCheck="false"
                  className="job-role-input"
                  onBlur={(e) => e.target.focus()}
                  value={applicationGoal}
                  onChange={handleApplicationGoalChange}
                  style={{ 
                    WebkitAppearance: 'none',
                    MozAppearance: 'textfield'
                  }}
                />
              </>
            )}
            <div className="next-wrapper">
              <button onClick={nextStep} className="nav-button next-button">
                Next
              </button>
              <div className="enter-shortcut">
                <span className="enter-icon">↵</span>
                <span className="enter-text">Or Press Enter</span>
              </div>
            </div>
          </div>
        );
      case 7:
        return (
          <>
            <div style={{ 
              position: 'fixed', 
              top: 0, 
              left: 0, 
              width: '100%', 
              height: '100%', 
              zIndex: 9999,
              pointerEvents: 'none' 
            }}>
              <ReactConfetti
                width={windowDimensions.width}
                height={windowDimensions.height}
                numberOfPieces={200}
                recycle={true}
                colors={['#0033A0', '#6C63FF', '#5A52D0', '#FFD700', '#FF69B4']}
                gravity={0.3}
                tweenDuration={5000}
              />
            </div>
            <div className="centered-step">
              <h1>You're All Set!</h1>
              <p>Thank you for providing your information.</p>
              <button className="finish-button" onClick={handleFinish}>
                Finish
              </button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="onboarding-container">
      <ProgressBar step={step} totalSteps={totalSteps} />
      {step === 1 && (
        <div className="floating-emoji-background">
          <div className="emoji emoji-1">🚀</div>
          <div className="emoji emoji-2">🌟</div>
          <div className="emoji emoji-3">💼</div>
        </div>
      )}
      <div className={`step-content ${fadeClass}`}>{renderStepContent()}</div>
    </div>
  );
};

export default Onboarding;
