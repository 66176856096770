import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => (
  <Helmet>
    <title>QuickCruit - Your Fastest Path to a Job</title>
    <meta name="description" content="Discover your dream job with QuickCruit's AI-powered job matching and resume tailoring services. Find jobs effortlessly." />
    <meta name="keywords" content="job search, AI resume builder, job matching, QuickCruit, career services" />
    <meta name="author" content="QuickCruit" />
    <script type="application/ld+json">
      {`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "QuickCruit",
          "url": "https://www.quickcruit.ai",
          "logo": "https://www.quickcruit.ai/logo.png",
          "sameAs": [
            "https://www.linkedin.com/company/QuickCruit"
          ]
        }
      `}
    </script>
  </Helmet>
);

export default SEO;
