import React, { useEffect } from "react";
import styles from "../styles/NewJobBenefits.module.scss";
import newJobBenefitsSvg from "../assets/new-job-benefits.svg";

const NewJobBenefits: React.FC = () => {
  useEffect(() => {
    const animationLoop = () => {
      const obj = document.querySelector('.newJobBenefitsBackground') as HTMLObjectElement;
      const svg = obj?.contentDocument?.querySelector('svg');
      if (!svg) return;

      // Get all the groups
      const higherResponseRate = svg.querySelector('g[id="higher-response-rate"]') as HTMLElement;
      const lessCompetition = svg.querySelector('g[id="less-compeitition"]') as HTMLElement;
      const showsProactivity = svg.querySelector('g[id="shows-proactivity"]') as HTMLElement;

      if (!higherResponseRate || !lessCompetition || !showsProactivity) return;

      // Initial state - hide all groups
      [higherResponseRate, lessCompetition, showsProactivity].forEach(group => {
        group.style.transition = 'all 1s cubic-bezier(0.4, 0, 0.2, 1)';
        group.style.opacity = '0';
        group.style.transform = 'translateY(10px)';
      });

      const sequence = async () => {
        // Start with all hidden
        [higherResponseRate, lessCompetition, showsProactivity].forEach(group => {
          group.style.opacity = '0';
          group.style.transform = 'scale(0.9) translateY(10px)';
        });

        await new Promise(resolve => setTimeout(resolve, 1000));

        // Show first item
        higherResponseRate.style.opacity = '1';
        higherResponseRate.style.transform = 'scale(1) translateY(0)';
        
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Add second item while keeping first
        lessCompetition.style.opacity = '1';
        lessCompetition.style.transform = 'scale(1) translateY(0)';
        
        await new Promise(resolve => setTimeout(resolve, 2000));

        // Add third item while keeping first two
        showsProactivity.style.opacity = '1';
        showsProactivity.style.transform = 'scale(1) translateY(0)';
        
        // Keep all three visible for a while
        await new Promise(resolve => setTimeout(resolve, 4000));

        // Fade out all items
        [higherResponseRate, lessCompetition, showsProactivity].forEach(group => {
          group.style.opacity = '0';
          group.style.transform = 'scale(0.9) translateY(10px)';
        });

        await new Promise(resolve => setTimeout(resolve, 1000));
      };

      sequence();
    };

    // Start animation when SVG loads
    const obj = document.querySelector('.newJobBenefitsBackground') as HTMLObjectElement;
    let intervalId: NodeJS.Timeout;

    const startAnimation = () => {
      animationLoop();
      // Total animation duration: 10 seconds (1 + 2 + 2 + 4 + 1)
      intervalId = setInterval(animationLoop, 10000);
    };

    obj?.addEventListener('load', startAnimation);

    return () => {
      if (intervalId) clearInterval(intervalId);
      obj?.removeEventListener('load', startAnimation);
    };
  }, []);

  return (
    <div className={styles.newJobBenefitsContainer}>
      <object
        className="newJobBenefitsBackground"
        data={newJobBenefitsSvg}
        type="image/svg+xml"
      />
    </div>
  );
};

export default NewJobBenefits; 