import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC_Szv56gM9Ny_VZyitXERWbVehrXkxlnA",
  authDomain: "quickcruit-1.firebaseapp.com",
  projectId: "quickcruit-1",
  storageBucket: "quickcruit-1.appspot.com",
  messagingSenderId: "407665174995",
  appId: "1:407665174995:web:8b404f921f88b1756ca726",
  measurementId: "G-98FZ0CLEM5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);

const getIdToken = async () => {
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken();
  }
  throw new Error("No user is currently logged in.");
};

const getUID = () => {
  const user = auth.currentUser;
  if (user) {
    return user.uid;
  }
  throw new Error("No user is currently logged in.");
};

export { auth, googleProvider, db, app, getIdToken, getUID };
