import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/components/auth.scss';
import logoImage from '../logo.svg';
import axiosClient from '../axios-client';
import { Bounce, toast } from 'react-toastify';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  // const { signInWithGoogle, isLoaded } = useGoogleAuth();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      const response = await axiosClient.post('/auth/login', { email, password });
      const { user, tokens } = response.data;

      localStorage.setItem('accessToken', tokens.access.token);
      localStorage.setItem('refreshToken', tokens.refresh.token);
      localStorage.setItem('user', JSON.stringify(user));
      toast('Login Success', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      if (!user.userOnboarding) {
        window.location.reload();
        navigate('/onboarding');
      }
      window.location.reload();
      navigate('/dashboard');
    } catch (error: any) {
      setError(error.response?.data?.message || 'An error occurred during login');
      toast(error.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    }
  };

  // const handleGoogleLogin = async () => {
  //   if (!isLoaded) {
  //     setError('Google Sign-In is not ready yet. Please try again.');
  //     return;
  //   }

  //   try {
  //     const accessToken = await signInWithGoogle();

  //     const response = await axios.post('/api/auth/google', { accessToken });
  //     const { tokens } = response.data;

  //     localStorage.setItem('accessToken', tokens.access.token);
  //     localStorage.setItem('refreshToken', tokens.refresh.token);

  //     console.log('User logged in with Google');
  //     navigate('/');
  //   } catch (error: any) {
  //     setError(error.message || 'An error occurred during Google login');
  //   }
  // };

  return (
    <div className="auth-wrapper">
      <header className="auth-header">
        <Link to="/">
          <img src={logoImage} alt="QuickCruit Logo" className="auth-logo" />
        </Link>
      </header>
      <div className="auth-container">
        <h2>Sign In</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
          />
          <button type="submit" className="auth-button">Log In</button>
        </form>
        <p>
          Trouble Signing In? <Link to="/auth/forgot-password">Forgot Password?</Link>
        </p>
        <div className="or-divider">Or</div>
        <div className="google-signin">
          <span className="google-icon">G</span> Log in with Google
        </div>
        <p>
          Don't have an account? <Link to="/auth/signup">Create Account</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
