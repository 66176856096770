import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar'; // Import the Sidebar component
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import "../styles/components/resumeBuilder.scss"

const ResumeBuilder: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [summary, setSummary] = useState('');
  const [education, setEducation] = useState('');
  const [experience, setExperience] = useState('');
  const [skills, setSkills] = useState('');
  const pdfRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    updatePDF();
  }, [name, email, phone, address, summary, education, experience, skills]);

  const wrapText = (text: string, maxWidth: number, font: any, fontSize: number) => {
    const words = text.split(' ');
    const lines = [];
    let line = '';

    for (let word of words) {
      const testLine = line + word + ' ';
      const testWidth = font.widthOfTextAtSize(testLine, fontSize);
      if (testWidth > maxWidth) {
        lines.push(line.trim());
        line = word + ' ';
      } else {
        line = testLine;
      }
    }
    lines.push(line.trim());
    return lines;
  };

  const updatePDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const fontSize = 12;
    const lineHeight = fontSize + 2;
    let y = height - 4 * lineHeight;

    const drawText = (text: string, x: number, y: number) => {
      page.drawText(text, {
        x,
        y,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });
    };

    const drawWrappedText = (text: string, x: number, y: number, maxWidth: number) => {
      const lines = wrapText(text, maxWidth, font, fontSize);
      lines.forEach((line) => {
        drawText(line, x, y);
        y -= lineHeight;
      });
      return y;
    };

    drawText(`Name: ${name}`, 50, y);
    y -= lineHeight;
    drawText(`Email: ${email}`, 50, y);
    y -= lineHeight;
    drawText(`Phone: ${phone}`, 50, y);
    y -= lineHeight;
    drawText(`Address: ${address}`, 50, y);
    y -= lineHeight * 2;

    drawText(`Summary`, 50, y);
    y -= lineHeight;
    y = drawWrappedText(summary, 50, y, width - 100) - lineHeight * 2;

    drawText(`Education`, 50, y);
    y -= lineHeight;
    y = drawWrappedText(education, 50, y, width - 100) - lineHeight * 2;

    drawText(`Experience`, 50, y);
    y -= lineHeight;
    y = drawWrappedText(experience, 50, y, width - 100) - lineHeight * 2;

    drawText(`Skills`, 50, y);
    y -= lineHeight;
    drawWrappedText(skills, 50, y, width - 100);

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    if (pdfRef.current) {
      pdfRef.current.src = pdfUrl;
    }
  };

  const downloadPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 800]);
    const { width, height } = page.getSize();
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const fontSize = 12;
    const lineHeight = fontSize + 2;
    let y = height - 4 * lineHeight;

    const drawText = (text: string, x: number, y: number) => {
      page.drawText(text, {
        x,
        y,
        size: fontSize,
        font,
        color: rgb(0, 0, 0),
      });
    };

    const drawWrappedText = (text: string, x: number, y: number, maxWidth: number) => {
      const lines = wrapText(text, maxWidth, font, fontSize);
      lines.forEach((line) => {
        drawText(line, x, y);
        y -= lineHeight;
      });
      return y;
    };

    drawText(`Name: ${name}`, 50, y);
    y -= lineHeight;
    drawText(`Email: ${email}`, 50, y);
    y -= lineHeight;
    drawText(`Phone: ${phone}`, 50, y);
    y -= lineHeight;
    drawText(`Address: ${address}`, 50, y);
    y -= lineHeight * 2;

    drawText(`Summary`, 50, y);
    y -= lineHeight;
    y = drawWrappedText(summary, 50, y, width - 100) - lineHeight * 2;

    drawText(`Education`, 50, y);
    y -= lineHeight;
    y = drawWrappedText(education, 50, y, width - 100) - lineHeight * 2;

    drawText(`Experience`, 50, y);
    y -= lineHeight;
    y = drawWrappedText(experience, 50, y, width - 100) - lineHeight * 2;

    drawText(`Skills`, 50, y);
    y -= lineHeight;
    drawWrappedText(skills, 50, y, width - 100);

    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `${name.replace(' ', '')}_Resume.pdf`;
    link.click();
  };

  return (
    <div className="resume-builder-page">
      <Sidebar />
      <div className="resume-builder-content">
        <h2>Resume Builder</h2>
        <form className="resume-form">
          <section className="resume-section">
            <h3>Resume Header</h3>
            <label>
              First Name:
              <input type="text" value={name} onChange={(e) => setName(e.target.value)} />
            </label>
            <label>
              Last Name:
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              Email:
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </label>
            <label>
              Phone:
              <input type="tel" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </label>
            <label>
              Address:
              <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
            </label>
          </section>
          <section className="resume-section">
            <h3>Education</h3>
            <label>
              School Name:
              <input type="text" value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              Degree Type:
              <input type="text" value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              Major:
              <input type="text" value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              GPA:
              <input type="text" value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              Relevant Coursework (Optional):
              <textarea value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              Start Date:
              <input type="date" value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              End Date:
              <input type="date" value={education} onChange={(e) => setEducation(e.target.value)} />
            </label>
            <label>
              In-Progress:
              <input type="checkbox" />
            </label>
          </section>
          <section className="resume-section">
            <h3>Work Experience</h3>
            <label>
              Company:
              <input type="text" value={experience} onChange={(e) => setExperience(e.target.value)} />
            </label>
            <label>
              Position Title:
              <input type="text" value={experience} onChange={(e) => setExperience(e.target.value)} />
            </label>
            <label>
              City:
              <input type="text" value={experience} onChange={(e) => setExperience(e.target.value)} />
            </label>
            <label>
              State:
              <input type="text" value={experience} onChange={(e) => setExperience(e.target.value)} />
            </label>
            <label>
              Country:
              <input type="text" value={experience} onChange={(e) => setExperience(e.target.value)} />
            </label>
            <label>
              Description:
              <textarea value={experience} onChange={(e) => setExperience(e.target.value)} />
            </label>
          </section>
          <section className="resume-section">
            <h3>Skills & Certifications</h3>
            <label>
              Certifications:
              <input type="text" value={skills} onChange={(e) => setSkills(e.target.value)} />
            </label>
            <label>
              Skills & Languages:
              <textarea value={skills} onChange={(e) => setSkills(e.target.value)} />
            </label>
          </section>
        </form>
        <div className="preview-section">
          <h2>Preview</h2>
          <iframe ref={pdfRef} className="pdf-preview" title="Resume Preview"></iframe>
          <button onClick={downloadPDF} className="download-btn">Download PDF</button>
        </div>
      </div>
    </div>
  );
};

export default ResumeBuilder;
