// PortfolioPage.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import '../styles/components/portfolio.scss';
import { mainUrl } from '../constant';
import { FaGithub, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';
import { FaXTwitter } from 'react-icons/fa6';
import { MdDragIndicator } from 'react-icons/md'; // Import the drag icon
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';

interface PortfolioData {
    firstName: string;
    lastName: string;
    email: string;
    linkedin: string;
    phone: string;
    website: string;
    location: string;
    citizenship: string;
    education: [{
        degree: string;
        field: string;
        university: string;
        graduationDate: string;
        gpa: string;
    }];
    experience: [{
        title: string;
        company: string;
        location: string;
        duration: string;
        startDate: string;
        endDate: string;
        isCurrentPosition: boolean;
        description: Array<{ id: string, text: string }>
    }];
    projects: {
        title: string;
        projectName: string;
        duration: string;
        description: { id: string, text: string }[]
    }[];
    profileImage?: string;
    skills: string[];
    interests: string[];
    experienceLevel: string;
}

const PortfolioPage = () => {
    const { username } = useParams();
    const [portfolioData, setPortfolioData] = useState<PortfolioData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchPortfolioData = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get<PortfolioData>(`${mainUrl}/users/get-user/${username}`);
                setPortfolioData(response.data);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
                setError('Failed to load portfolio data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchPortfolioData();
    }, [username]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    if (!portfolioData) {
        return <div>No portfolio data found</div>;
    }

    return (
        <div className='account-info-container'>
            <div className='account-info'>
                <div className="profile-header">
                    <div className="left-section">
                        <div className="profile-info">
                            <div className="profile-picture">
                                {portfolioData.profileImage ? (
                                    <img src={portfolioData.profileImage} alt='Profile' />
                                ) : (
                                    <div className="default-icon">+</div>
                                )}
                            </div>
                            <h1>{portfolioData.firstName} {portfolioData.lastName}</h1>
                        </div>

                        <div className="profile-details">
                            <div className="skills-section">
                                {portfolioData.skills.slice(0, 3).map((skill: string, index: number) => (
                                    <span key={index} className='skill-badge'>{skill}</span>
                                ))}
                            </div>
                            <div className="experience-location">
                                <div className="info-item">
                                    <span className='info-value'>{portfolioData.experienceLevel}</span>
                                    <span className='info-label'>Exp. Level</span>
                                </div>
                                <div className="divider"></div>
                                <div className="info-item">
                                    <span className='info-value'>{portfolioData.location}</span>
                                    <span className='info-label'>Located</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="right-section">
                        <div className="social-icons">
                            <div className="social-icons">
                                <a href={`mailto:${portfolioData.email}`} target="_blank" rel="noopener noreferrer">
                                    <FaEnvelope />
                                </a>
                                <a href={portfolioData.linkedin} target="_blank" rel="noopener noreferrer">
                                    <FaLinkedin />
                                </a>
                                <a href={`https://github.com/${portfolioData.email.split('@')[0]}`} target="_blank" rel="noopener noreferrer">
                                    <FaGithub />
                                </a>
                                <a href={`https://twitter.com/${portfolioData.email.split('@')[0]}`} target="_blank" rel="noopener noreferrer">
                                    <FaXTwitter />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="input-field">
                        <label>Phone</label>
                        <span>{portfolioData.phone}</span>
                    </div>

                    <div className="input-field">
                        <label>Website</label>
                        <span>{portfolioData.website}</span>
                    </div>

                    <div className="input-field">
                        <label>Citizenship</label>
                        <span>{portfolioData.citizenship}</span>
                    </div>

                    <div className="section">
                        <h3 className='section-title'>Education</h3>
                        {portfolioData.education.map((edu, index) => (
                            <div className="education-item" key={index}>
                                <div className="info-content">
                                    <div className="item-title">
                                        {edu.degree ? `${edu.degree}, ${edu.field}` : edu.field}
                                    </div>
                                    <div className="item-duration">
                                        {edu.graduationDate}
                                    </div>
                                    <div className="item-subtitle">
                                        {edu.university}
                                        {edu.gpa && <span className="gpa"> | GPA: {edu.gpa}</span>}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="section">
                        <h3 className="section-title">Experience</h3>
                        {portfolioData.experience.map((exp, index) => (
                            <div key={index} className='experience-item'>
                                <div className="info-content">
                                    <div className="top-row">
                                        <div className="item-title">{exp.title}</div>
                                        <div className="item-duration">
                                            {exp.startDate} - {exp.isCurrentPosition ? 'Present' : exp.endDate}
                                        </div>
                                    </div>
                                    <div className="bottom-row">
                                        <div className="item-company">{exp.company}</div>
                                        <div className="item-location">{exp.location}</div>
                                    </div>
                                    <ul className="item-subtitle">
                                        {exp.description.map((desc, descIndex) => (
                                            <li key={descIndex}>{desc.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='section'>
                        <h3 className="section-title">Projects</h3>
                        {portfolioData.projects.map((project, index) => (
                            <div className='project-item' key={index}>
                                <div className="info-content">
                                    <div className="top-row">
                                        <div className="item-title">
                                            {project.title ? `${project.projectName} (${project.title})` : project.projectName}
                                        </div>
                                        {project.duration && (
                                            <div className="item-duration">
                                                {project.duration}
                                            </div>
                                        )}
                                    </div>
                                    <ul className="item-subtitle">
                                        {project.description.map((desc, descIndex) => (
                                            <li key={descIndex}>{desc.text}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="section">
                        <h3 className="section-title">Skills</h3>
                        <div className="skills-container">
                            {portfolioData.skills.map((skill, index) => (
                                <span key={index} className="skill-badge">
                                    {skill}
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioPage;
