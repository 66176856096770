import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import "../../styles/utilities/dropdown.scss"; // Ensure this path is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Translate } from "@styled-icons/bootstrap/Translate";
import { IoChevronDownSharp } from "react-icons/io5";

interface DropdownOption {
  label: string;
  description: string;
}

interface DropdownProps {
  options: DropdownOption[];
  placeholder?: string;
  onSelect: (selected: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option: DropdownOption) => {
    setSelectedOption(option.label);
    setIsOpen(false);
    onSelect(option.label);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`dropdown ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        <div className="dropdown-content">
          <Translate size={18} className="dropdown-icon" />
          <span
            style={{ fontSize: "17px", fontWeight: "900" }}
            className="dropdown-placeholder"
          >
            {selectedOption || placeholder || "Select an option"}
          </span>
        </div>
        <IoChevronDownSharp size={20} className="dropdown-arrow" />
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {options.map((option, index) => (
            <li
              key={index}
              className="dropdown-list-item"
              onClick={() => handleSelect(option)}
            >
              <div className="dropdown-item-label">{option.label}</div>
              <div className="dropdown-item-description">
                {option.description}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
