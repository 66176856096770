import React from "react";
import "../../styles/components/progressBar.scss";

interface ProgressBarProps {
  step: number;
  totalSteps: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  step,
  totalSteps,
}: ProgressBarProps) => {
  const percentage = ((step - 1) / (totalSteps - 1)) * 100;

  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar-filled"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;