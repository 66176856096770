import React from "react";
import "../../styles/components/landing-pages/footer.scss";
import logoImage from "../../assets/logo.svg";
import { relative } from "path";
import Dropdown from "../../components/ui/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faXTwitter,
  faLinkedin,
  faFacebook,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
const Footer: React.FC = () => {
  const handleSelect = (selected: string) => {
    console.log("Selected option:", selected);
  };
  return (
    <footer className="footer">
      <div className="footer__social-media">
        <img src={logoImage} alt="Logo" className="footer__logo" />
        <div className="footer__icons">
          <a href="https://instagram.com" aria-label="Instagram">
            <FontAwesomeIcon icon={faInstagram} size = {"lg"} className="social-icon" />
          </a>
          <a href="https://x.com" aria-label="X">
            <FontAwesomeIcon icon={faXTwitter} size = {"lg"} className="social-icon" />
          </a>
          <a href="https://linkedin.com" aria-label="LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} size = {"lg"} className="social-icon" />
          </a>
          <a href="https://facebook.com" aria-label="Facebook">
            <FontAwesomeIcon icon={faFacebook} size = {"lg"} className="social-icon" />
          </a>
          <a href="https://youtube.com" aria-label="YouTube">
            <FontAwesomeIcon icon={faYoutube} size = {"lg"} className="social-icon" />
          </a>
        </div>

        <Dropdown
          options={[
            { label: "English", description: "English (US)" },
            { label: "Français", description: "French (France)" },
            { label: "Deutsch", description: "German" },
            { label: "日本語", description: "Japanese" },
            { label: "한국어", description: "Korean" },
          ]}
          placeholder="English"
          onSelect={handleSelect}
        />
        
        {/* <a href="#">Do Not Sell or Share My Info</a>
        <p>Cookie settings</p> */}
        <p>&copy; 2024 Quickruit, Inc.</p>
      </div>

      <div className="footer__links">
        <div>
          <h4>Company</h4>
          <ul>
            <li>
              <a href="#">About us</a>
            </li>
            <li>
              <a href="#">Careers</a>
            </li>
            <li>
              <a href="#">Security</a>
            </li>
            <li>
              <a href="#">Change Log</a>
            </li>
            <li>
              <a href="#">Terms & privacy</a>
            </li>
          </ul>
        </div>

        <div>
          <h4>Features</h4>
          <ul>
            <li>
              <a href="#">QuickCruit Extension</a>
            </li>
            {/* <li>
              <a href="#">Mac & Windows</a>
            </li> */}
          </ul>
        </div>

        <div>
          <h4>Resources</h4>
          <ul>
            <li>
              <a href="#">Help center</a>
            </li>
            <li>
              <Link to="/pricing">Pricing</Link>
            </li>
            <li>
              <a href="#">Blog</a>
            </li>
            <li>
              <a href="#">Community</a>
            </li>
            <li>
              <a href="#">Integrations</a>
            </li>
            <li>
              <a href="#">Templates</a>
            </li>
            <li>
              <a href="#">Affiliates</a>
            </li>
          </ul>
        </div>

        <div>
          <h4>Quickruit for</h4>
          <ul>
            <li>
              <a href="#">Enterprise</a>
            </li>
            <li>
              <a href="#">Small business</a>
            </li>
            <li>
              <a href="#">Personal</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;