import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // Force immediate scroll position
    document.documentElement.style.scrollBehavior = 'auto';
    document.body.style.scrollBehavior = 'auto';
    
    window.scrollTo(0, 0);
    
    // Reset scroll behavior
    document.documentElement.style.scrollBehavior = '';
    document.body.style.scrollBehavior = '';
  }, [pathname]);

  return null;
};

export default ScrollToTop; 