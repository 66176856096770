import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import "./styles/main.scss";
import LandingPage from "./components/landing-pages/LandingPage";
import Signup from "./components/Signup";
import Login from "./components/Login";
import VerifyEmail from "./components/VerifyEmail";
import ForgotPassword from "./components/ForgotPassword";
import Dashboard from "./components/Dashboard";
import Metrics from "./components/Metrics";
import AccountInfo from "./components/AccountInfo";
import JobSearch from "./components/JobSearch";
import Sidebar from "./components/Sidebar";
import ResumeBuilder from "./components/ResumeBuilder";
import Preferences from "./components/Preferences";
import SEO from "./SEO";
import EmailVerified from "./components/EmailVerified";
import Onboarding from "./components/Onboarding";
import "@radix-ui/themes/styles.css";
import JobPage from "./components/landing-pages/JobPage";
import PortfolioPage from "./components/Portfolio";
import PricingPage_second from "./components/landing-pages/Pricing";
import WhyUs from "./components/landing-pages/WhyUs";
import "./index.css";
import ScrollToTop from './components/ScrollToTop';

const Loading: React.FC = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    }}
  >
    <div className="spinner"></div>
  </div>
);

function App() {
  const [user, setUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const storedUser = localStorage.getItem("user");

    if (token && storedUser) {
      setUser(JSON.parse(storedUser));
      setLoading(false);
    } else {
      setUser(null);
      setLoading(false);
    }
  }, []);

  const LayoutWithSidebar = ({ children }: { children: React.ReactNode }) => (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <Sidebar />
      <div style={{ flex: 1 }}>{children}</div>
    </div>
  );

  const handleSavePreferences = (
    locations: any[],
    jobTypes: any[],
    industries: any[],
    resumeFile: File | null
  ) => {
    // This function is just a placeholder and will not be used
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <ScrollToTop />
      <SEO />
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/dashboard" /> : <LandingPage />}
          // For testing purposes
          // element={user ? <Navigate to="/dashboard" /> : <JobPage />}
        />
        <Route
          path="/dashboard"
          element={
            user ? (
              <LayoutWithSidebar>
                <Dashboard />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/metrics"
          element={
            user ? (
              <LayoutWithSidebar>
                <Metrics />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/account-info"
          element={
            user ? (
              <LayoutWithSidebar>
                <AccountInfo />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/jobs"
          // element={
          //   user ? (
          //     <LayoutWithSidebar>
          //       <JobPage />
          //     </LayoutWithSidebar>
          //   ) : (
          //     <Navigate to="/auth/login" />
          //   )
          // }
          element={<JobPage />}
        />
        <Route
          path="/job-search"
          element={
            user ? (
              <LayoutWithSidebar>
                <JobSearch />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/job-search/preferences"
          element={
            user ? (
              <LayoutWithSidebar>
                <Preferences onSave={handleSavePreferences} />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/job-search/jobs"
          element={
            user ? (
              <LayoutWithSidebar>
                <JobSearch />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route
          path="/resume-builder"
          element={
            user ? (
              <LayoutWithSidebar>
                <ResumeBuilder />
              </LayoutWithSidebar>
            ) : (
              <Navigate to="/auth/login" />
            )
          }
        />
        <Route path="/auth/signup" element={<Signup />} />
        <Route
          path="/auth/login"
          element={user ? <Navigate to="/dashboard" /> : <Login />}
        />
        <Route path="/auth/verify-email" element={<VerifyEmail />} />
        <Route path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route path="/auth/email-verified" element={<EmailVerified />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/logout" element={<Logout setUser={setUser} />} />
        <Route path="/portfolio/:username" element={<PortfolioPage />} />
        <Route path="/pricing" element={<PricingPage_second />} />
        <Route path="why-us" element={<WhyUs />} />
      </Routes>
    </Router>
  );
}

const Logout: React.FC<{ setUser: (user: any) => void }> = ({ setUser }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = () => {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      setUser(null);
      navigate("/");
    };

    logoutUser();
  }, [navigate, setUser]);

  return null;
};

export default App;
