import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/components/auth.scss';
import { Link } from 'react-router-dom';
import logoImage from '../logo.svg';

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(async () => {
      const user = auth.currentUser;
      if (user && user.emailVerified) {
        clearInterval(interval);
        navigate('/auth/login');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <div className="auth-wrapper">
      <header className="auth-header">
        <Link to="/">
          <img src={logoImage} alt="QuickCruit Logo" className="auth-logo" />
        </Link>
      </header>
      <div className="auth-container">
        <h2>Verify Your Email</h2>
        <p>We have sent you an email with a verification link. Please check your inbox and click on the link to verify your email address.</p>
        <p>If you did not receive the email, please check your spam folder or <Link to="/auth/resend-verification">click here</Link> to resend the verification email.</p>
      </div>
    </div>
  );
};

export default VerifyEmail;