import axiosClient from "../axios-client";
// src/services/jobService.js
export const fetchJobs = async () => {
    try {
        const response = await axiosClient.get('/jobs/getJobs');
        console.log(response)
        const data = response.data
        return data;
    } catch (error) {
        console.error("Error fetching jobs:", error);
        throw error;
    }
};