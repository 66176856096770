import Navbar from './Navbar'
import '../../index.css'
const WhyUs = () => {
    return (
        <div>
            <Navbar />

            <main className="pt-40 md:pt-20">
                <section className="section relative py-10 md:pt-20 pb-6">
                    <div className="container">
                        <div>
                            <img alt="" loading="lazy" width="2880" height="1489" decoding="async" data-nimg="1" className="absolute bottom-0 left-0 right-0 top-0 -z-10" srcSet="https://images.prismic.io/evernote/662696d4-f489-41d3-9ffb-1f6c9e1a624f_hero-background.webp?auto=compress%2Cformat&amp;fit=max&amp;w=3840 1x" src="https://images.prismic.io/evernote/662696d4-f489-41d3-9ffb-1f6c9e1a624f_hero-background.webp?auto=compress%2Cformat&amp;fit=max&amp;w=3840" />
                            <div className="rich-wrapper--hero-vertical flex w-full flex-col justify-center text-center"><h1>Why choose Evernote?</h1>
                                <p>With Evernote, you have everything you need to keep life organized. Use it for note taking, project planning, and to find what you need, when you need it.</p>
                                <a className="rounded-md border border-black text-center transition-all ease-in-out 
  hover:-translate-y-px hover:shadow-button
  min-w-[320px] py-2.5 lg:py-5 
  px-10 bg-accent text-colors-eggshell mb-8 mt-12 self-center
  undefined" href="https://accounts.evernote.com/get-started?platform=windows&amp;browser=chrome&amp;osVersion=10.0&amp;ctaType=signup&amp;experiment=no_experiment&amp;redirect_to=https%3A%2F%2Fevernote.com%2Fdownload-evernote&amp;website_id=073e81e7-72be-4686-9a85-1470b4d4ee9c&amp;is_obe=true" rel="noreferrer"><span className="whitespace-nowrap font-medium 
      text-[18px] 
      undefined">Get Evernote free</span></a><span>Already have an account? <a href="https://www.evernote.com/Login.action" rel="noreferrer">Log in now</a>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section py-16 lg:py-30 ">
                    <div className="container--narrow container flex flex-col items-center">
                        <div className="flex flex-col items-center space-y-14 text-center md:flex-row md:space-x-12 md:space-y-0 md:text-left">
                            <figure className="h-52 w-52 shrink-0">
                                <img alt="colin, Evernote Certified Expert" loading="lazy" width="208" height="208" decoding="async" data-nimg="1" className="object-contain object-center" srcSet="/_next/image?url=%2Fimages%2Ftestimonials%2Fexperts%2Fcolin.webp&amp;w=256&amp;q=75 1x, /_next/image?url=%2Fimages%2Ftestimonials%2Fexperts%2Fcolin.webp&amp;w=640&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ftestimonials%2Fexperts%2Fcolin.webp&amp;w=640&amp;q=75" />
                            </figure>
                            <div>
                                <h4 className="headline headline--4 text black mb-10">Every day and in every way, Evernote is there for my work and my life. It's my most used app, both at my desk and in my pocket.</h4>
                                <div className="headline headline--6 mb-4 flex items-center font-bold uppercase text-black">
                                    <p>Colin Whalen, Evernote Certified Expert</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section overflow-hidden py-10 lg:pt-20 lg:pb-24">
                    <div className="container--narrow container">
                        <div className="rich-wrapper--hero-vertical mb-14 flex-col space-y-4 text-center">
                            <h2>Your second brain</h2>
                            <p>Capture everything that is, was, or could be important, and access it on all your devices.</p>
                        </div>
                        <div className="slick-slider slick-initialized" dir="ltr">
                            <div className="absolute top-1/2 -left-11 z-10 hidden h-16 w-16 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-black bg-colors-eggshell transition-transform ease-out hover:scale-105 md:flex xl:h-20 xl:w-20 slick-arrow slick-prev">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 12L5 12" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 19L5 12L12 5" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                </svg>
                            </div>
                            <div className="slick-list">
                                <div className="slick-track" style={{ width: '2052px', opacity: 1, transform: 'translate3d(-668px, 0px, 0px)' }}>
                                    <div data-index="0" className="slick-slide" aria-hidden="true" style={{ outline: 'none' }}>
                                        <div>
                                            <a className="flex h-full max-w-[260px] flex-col justify-between rounded-lg border border-black p-8 md:max-w-[310px] undefined transition-shadow ease-in-out hover:-translate-y-px hover:shadow-button" href="/features/home">
                                                <figure className="mb-9 h-24 w-24">
                                                    <img alt="icon" loading="lazy" width="96" height="96" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fflexible.webp&amp;w=96&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fflexible.webp&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fflexible.webp&amp;w=256&amp;q=75" style={{ color: 'transparent' }} />
                                                </figure>
                                                <div>
                                                    <h5 className="headline headline--5 mb-2 font-semibold text-black">Flexible organization</h5>
                                                    <p className="body-text body-text--2 text-grey-25">Evernote doesn’t force you to organize a certain way. Create a system of notebooks or don’t organize at all. Any note is a quick search away.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div data-index="1" className="slick-slide" aria-hidden="true" style={{ outline: 'none' }}>
                                        <div>
                                            <a className="flex h-full max-w-[260px] flex-col justify-between rounded-lg border border-black p-8 md:max-w-[310px] undefined transition-shadow ease-in-out hover:-translate-y-px hover:shadow-button" href="/download">
                                                <figure className="mb-9 h-24 w-24">
                                                    <img alt="icon" loading="lazy" width="96" height="96" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Feverywhere.webp&amp;w=96&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Feverywhere.webp&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Feverywhere.webp&amp;w=256&amp;q=75" style={{ color: 'transparent' }} />
                                                </figure>
                                                <div>
                                                    <h5 className="headline headline--5 mb-2 font-semibold text-black">Available everywhere</h5>
                                                    <p className="body-text body-text--2 text-grey-25">Have an iPhone and a Windows computer? Android and Mac? Unlike most note-taking apps, Evernote works on them all.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div data-index="2" className="slick-slide slick-active slick-current" aria-hidden="false" style={{ outline: 'none' }}>
                                        <div>
                                            <a className="flex h-full max-w-[260px] flex-col justify-between rounded-lg border border-black p-8 md:max-w-[310px] undefined transition-shadow ease-in-out hover:-translate-y-px hover:shadow-button" href="/features/webclipper">
                                                <figure className="mb-9 h-24 w-24">
                                                    <img alt="icon" loading="lazy" width="96" height="96" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fweb-clipper.webp&amp;w=96&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fweb-clipper.webp&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fweb-clipper.webp&amp;w=256&amp;q=75" style={{ color: 'transparent' }} />
                                                </figure>
                                                <div>
                                                    <h5 className="headline headline--5 mb-2 font-semibold text-black">Web Clipper</h5>
                                                    <p className="body-text body-text--2 text-grey-25">Some note-taking apps strictly limit what you can save online. Evernote’s Web Clipper lets you save and annotate web pages, images, and PDFs.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div data-index="3" className="slick-slide slick-active" aria-hidden="false">
                                        <div>
                                            <a className="flex h-full max-w-[260px] flex-col justify-between rounded-lg border border-black p-8 md:max-w-[310px] undefined transition-shadow ease-in-out hover:-translate-y-px hover:shadow-button" href="/features/search">
                                                <figure className="mb-9 h-24 w-24">
                                                    <img alt="icon" loading="lazy" width="96" height="96" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fcharacter-recognition.webp&amp;w=96&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fcharacter-recognition.webp&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fcharacter-recognition.webp&amp;w=256&amp;q=75" />
                                                </figure>
                                                <div>
                                                    <h5 className="headline headline--5 mb-2 font-semibold text-black">Character recognition</h5>
                                                    <p className="body-text body-text--2 text-grey-25">Find notes by searching for keywords, even if the words appear in photos, whiteboard scans, business cards, handwriting, or documents.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div data-index="4" className="slick-slide slick-active" aria-hidden="false" style={{ outline: 'none' }}>
                                        <div>
                                            <a className="flex h-full max-w-[260px] flex-col justify-between rounded-lg border border-black p-8 md:max-w-[310px] undefined transition-shadow ease-in-out hover:-translate-y-px hover:shadow-button" href="/features/notes-app"><figure className="mb-9 h-24 w-24"><img alt="icon" loading="lazy" width="96" height="96" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fweb-app.webp&amp;w=96&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fweb-app.webp&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fweb-app.webp&amp;w=256&amp;q=75" style={{ color: 'transparent' }} />
                                            </figure>
                                                <div>
                                                    <h5 className="headline headline--5 mb-2 font-semibold text-black">Web application</h5>
                                                    <p className="body-text body-text--2 text-grey-25">Many note-taking apps lack a fully functional web application. Evernote Web offers a complete lineup of features from any major browser.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <div data-index="5" className="slick-slide" aria-hidden="true"
                                    ><div>
                                            <a className="flex h-full max-w-[260px] flex-col justify-between rounded-lg border border-black p-8 md:max-w-[310px] undefined transition-shadow ease-in-out hover:-translate-y-px hover:shadow-button" href="/integrations">
                                                <figure className="mb-9 h-24 w-24"
                                                ><img alt="icon" loading="lazy" width="96" height="96" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fapps.webp&amp;w=96&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fapps.webp&amp;w=256&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fapps.webp&amp;w=256&amp;q=75" style={{ color: 'transparent' }} />
                                                </figure>
                                                <div>
                                                    <h5 className="headline headline--5 mb-2 font-semibold text-black">App integrations</h5>
                                                    <p className="body-text body-text--2 text-grey-25">Evernote works with the apps you rely on, including Google Drive, Slack, Outlook, and Gmail.</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="absolute top-1/2 z-10 hidden h-16 w-16 -translate-y-1/2 rotate-180 cursor-pointer items-center justify-center rounded-full border border-black bg-colors-eggshell transition-transform ease-out hover:scale-105 md:right-8 md:flex lg:-right-4 xl:right-0 xl:h-20 xl:w-20 slick-arrow slick-next">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 12L5 12" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M12 19L5 12L12 5" stroke="#171717" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    </path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section border-y border-black bg-white bg-cover bg-center bg-no-repeat background-image--green-light-one">
                    <div className="container text-center">
                        <div className="rich-wrapper--cta flex w-full flex-col justify-center pt-36 pb-36 text-colors-eggshell">
                            <h2>Feel the calm of being more organized</h2>
                            <div className="mt-10 flex justify-center space-x-8">
                                <a className="rounded-md border border-black text-center transition-all ease-in-out hover:-translate-y-px hover:shadow-button min-w-[320px] py-2.5 lg:py-5  px-10 bg-colors-eggshell text-black undefined undefined" href="https://accounts.evernote.com/get-started?platform=windows&amp;browser=chrome&amp;osVersion=10.0&amp;ctaType=signup&amp;experiment=no_experiment&amp;redirect_to=https%3A%2F%2Fevernote.com%2Fdownload-evernote&amp;website_id=073e81e7-72be-4686-9a85-1470b4d4ee9c&amp;is_obe=true" rel="noreferrer"><span className="whitespace-nowrap font-medium text-[18px] undefined">Get Evernote free</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section py-10 lg:py-20">
                    <div className="container--narrow container">
                        <div className="flex w-full flex-col items-center justify-between space-y-6 lg:space-y-0 lg:flex-row">
                            <div className="flex w-full basis-[45%] flex-col space-y-4 lg:pr-9"><div className="rich-wrapper--split-content">
                                <h2>Hit every deadline</h2>
                                <p>Link notes containing important action items to your calendar events, and Evernote will surface the info you need—right when you need it.</p>
                                <div className="mt-10 flex items-center space-x-8">
                                    <a className="link-text" href="/features/notes-app">Discover note taking</a>
                                </div>
                            </div>
                            </div>
                            <div className="flex basis-auto items-center justify-center lg:basis-[55%]">
                                <figure className="w-full">
                                    <figure className="w-full lg:pl-12 2xl:w-[640px]">
                                        <img alt="A UI rappresentation of to-do" loading="lazy" width="640" height="640" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Fsplit%2Fto-do.webp&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Fsplit%2Fto-do.webp&amp;w=1920&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Fsplit%2Fto-do.webp&amp;w=1920&amp;q=75" />
                                    </figure>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section py-10 lg:py-20">
                    <div className="container--narrow container">
                        <div className="flex w-full flex-col items-center justify-between space-y-6 lg:space-y-0 lg:flex-row-reverse">
                            <div className="flex w-full basis-[45%] flex-col space-y-4 lg:pl-9">
                                <div className="rich-wrapper--split-content">
                                    <h2>Capture more than words</h2>
                                    <p>Get ideas down fast and combine them with images, sketches, documents, and audio recordings to turn passing thoughts into plans for action.</p>
                                    <div className="mt-10 flex items-center space-x-8">
                                        <a className="rounded-md border border-black text-center transition-all ease-in-out hover:-translate-y-px hover:shadow-button min-w-[154px] py-2 px-10 bg-accent text-colors-eggshell undefined undefined" href="/features/document-scanning">
                                            <span className="whitespace-nowrap font-medium text-[16px] undefined">Find out more</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex basis-auto items-center justify-center lg:basis-[55%]">
                                <figure className="w-full">
                                    <figure className="w-full lg:pl-12 2xl:w-[640px]">
                                        <img alt="A UI rappresentation of sketches" loading="lazy" width="640" height="640" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Fsplit%2Fsketches.webp&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Fsplit%2Fsketches.webp&amp;w=1920&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Fsplit%2Fsketches.webp&amp;w=1920&amp;q=75" style={{ color: 'transparent' }} />
                                    </figure>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section py-10 lg:py-20">
                    <div className="container--narrow container">
                        <div className="flex w-full flex-col items-center justify-between space-y-6 lg:space-y-0 lg:flex-row">
                            <div className="flex w-full basis-[45%] flex-col space-y-4 lg:pr-9">
                                <div className="rich-wrapper--split-content">
                                    <h3>Find it fast</h3>
                                    <p>Keep all your project materials in one place—including checklists, emails, and PDFs. Smart search puts it all at your fingertips.</p>
                                    <div className="mt-10 flex items-center space-x-8">
                                        <a className="link-text" href="/features/search">Check out advanced search</a>
                                    </div>
                                </div>
                            </div>
                            <div className="flex basis-auto items-center justify-center lg:basis-[55%]">
                                <figure className="w-full">
                                    <figure className="w-full lg:pl-12 2xl:w-[640px]">
                                        <img alt="A UI rappresentation of easy-search" loading="lazy" width="640" height="640" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Fsplit%2Feasy-search.webp&amp;w=640&amp;q=75 1x, /_next/image?url=%2Fimages%2Fsplit%2Feasy-search.webp&amp;w=1920&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Fsplit%2Feasy-search.webp&amp;w=1920&amp;q=75" style={{ color: 'transparent' }} />
                                    </figure>
                                </figure>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section pt-10 pb-20 lg:pt-20 lg:pb-30">
                    <div className="container--narrow container">
                        <h2 className="headline headline--2 mx-auto mb-30 max-w-[720px] text-center">Get started with our templates</h2>
                        <div className="flex w-full flex-col space-y-8">
                            <div className="mb-16 grid grid-cols-1 gap-y-8  md:grid-cols-2 md:gap-x-8 lg:grid-cols-3 lg:gap-x-6">
                                <a className="flex h-full flex-col justify-between rounded-lg border border-black py-6 pl-6 text-black transition-all ease-in-out hover:-translate-y-px hover:shadow-button lg:py-8 lg:pl-8" href="https://www.evernote.com/shard/s308/sh/4c71fdea-b291-4af4-afd5-ea94e4c58cf9/d16b7cc4e1bc0acd98639a99e77b8ac0?displayMode=template" rel="noreferrer">
                                    <div>
                                        <h6 className="headline headline--6 mb-6 pr-6 font-bold uppercase lg:pr-8">personal well being</h6>
                                        <figure className="mb-10 w-full rounded-t-md bg-white">
                                            <img alt="" loading="lazy" width="704" height="480" decoding="async" data-nimg="1" className="h-full w-full object-cover" srcSet="https://images.prismic.io/evernote/efb8dc16-1b3e-4ad2-ae21-f175b317253a_weightloss-hover-64f460d428467.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750 1x, https://images.prismic.io/evernote/efb8dc16-1b3e-4ad2-ae21-f175b317253a_weightloss-hover-64f460d428467.webp?auto=compress%2Cformat&amp;fit=max&amp;w=1920 2x" src="https://images.prismic.io/evernote/efb8dc16-1b3e-4ad2-ae21-f175b317253a_weightloss-hover-64f460d428467.webp?auto=compress%2Cformat&amp;fit=max&amp;w=1920" style={{ color: 'transparent' }} /></figure>
                                    </div>
                                    <div>
                                        <h5 className="headling headline--5 mb-2 pr-6 font-semibold lg:pr-8">Weight loss tracker</h5>
                                        <p className="body-text body-text--2 pr-6 text-grey-25 lg:pr-8">Stay aware of your daily progress toward your goal weight.</p>
                                    </div>
                                </a>
                                <a className="flex h-full flex-col justify-between rounded-lg border border-black py-6 pl-6 text-black transition-all ease-in-out hover:-translate-y-px hover:shadow-button lg:py-8 lg:pl-8" href="https://www.evernote.com/shard/s308/sh/fbcba791-31b7-4340-909c-178dc704d3ab/62072d12e572559ffb195fdba05f8a51?displayMode=template" rel="noreferrer">
                                    <div>
                                        <h6 className="headline headline--6 mb-6 pr-6 font-bold uppercase lg:pr-8">personal well being</h6>
                                        <figure className="mb-10 w-full rounded-t-md bg-white">
                                            <img alt="" loading="lazy" width="704" height="480" decoding="async" data-nimg="1" className="h-full w-full object-cover" srcSet="https://images.prismic.io/evernote/8d027384-ee27-4042-b67f-6f91842f77fe_goals-64f460c830a3a.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750 1x, https://images.prismic.io/evernote/8d027384-ee27-4042-b67f-6f91842f77fe_goals-64f460c830a3a.webp?auto=compress%2Cformat&amp;fit=max&amp;w=1920 2x" src="https://images.prismic.io/evernote/8d027384-ee27-4042-b67f-6f91842f77fe_goals-64f460c830a3a.webp?auto=compress%2Cformat&amp;fit=max&amp;w=1920" style={{ color: 'transparent' }} />
                                        </figure>
                                    </div>
                                    <div>
                                        <h5 className="headling headline--5 mb-2 pr-6 font-semibold lg:pr-8">Goal setting</h5>
                                        <p className="body-text body-text--2 pr-6 text-grey-25 lg:pr-8">Keep an overview of your biggest goals throughout the entire year.</p>
                                    </div>
                                </a>
                                <a className="flex h-full flex-col justify-between rounded-lg border border-black py-6 pl-6 text-black transition-all ease-in-out hover:-translate-y-px hover:shadow-button lg:py-8 lg:pl-8" href="https://www.evernote.com/shard/s308/sh/d0d9fc62-c1a2-4265-8502-573981fb6adb/52e02df8257670449de0c7ea45198cd0?displayMode=template" rel="noreferrer">
                                    <div>
                                        <h6 className="headline headline--6 mb-6 pr-6 font-bold uppercase lg:pr-8">career</h6>
                                        <figure className="mb-10 w-full rounded-t-md bg-white">
                                            <img alt="" loading="lazy" width="704" height="480" decoding="async" data-nimg="1" className="h-full w-full object-cover" srcSet="https://images.prismic.io/evernote/ffb39954-06b9-4987-b761-0c178910c735_applicationtracker-on2-64f460bf4fa57.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750 1x, https://images.prismic.io/evernote/ffb39954-06b9-4987-b761-0c178910c735_applicationtracker-on2-64f460bf4fa57.webp?auto=compress%2Cformat&amp;fit=max&amp;w=1920 2x" src="https://images.prismic.io/evernote/ffb39954-06b9-4987-b761-0c178910c735_applicationtracker-on2-64f460bf4fa57.webp?auto=compress%2Cformat&amp;fit=max&amp;w=1920" style={{ color: 'transparent' }} />
                                        </figure>
                                    </div>
                                    <div>
                                        <h5 className="headling headline--5 mb-2 pr-6 font-semibold lg:pr-8">Job application tracker</h5>
                                        <p className="body-text body-text--2 pr-6 text-grey-25 lg:pr-8">Keep track of where you’ve applied, when, and what your next steps are.</p>
                                    </div></a>
                                <a className="flex h-full flex-col justify-between rounded-lg border border-black py-6 pl-6 text-black transition-all ease-in-out hover:-translate-y-px hover:shadow-button lg:py-8 lg:pl-8" href="https://www.evernote.com/shard/s308/sh/c5e7e43b-20de-4fbe-91a6-dc01ed02c4f5/fb13c2a5936f636af198ed2bfd170ccd?displayMode=template" rel="noreferrer">
                                    <div>
                                        <h6 className="headline headline--6 mb-6 pr-6 font-bold uppercase lg:pr-8">creative writing</h6>
                                        <figure className="mb-10 w-full rounded-t-md bg-white">
                                            <img alt="" loading="lazy" width="352" height="240" decoding="async" data-nimg="1" className="h-full w-full object-cover" srcSet="https://images.prismic.io/evernote/19769d48-26ae-47df-bd1c-578fcd4e1645_character-profile-on-64f460c04ca49.webp?auto=compress%2Cformat&amp;fit=max&amp;w=384 1x, https://images.prismic.io/evernote/19769d48-26ae-47df-bd1c-578fcd4e1645_character-profile-on-64f460c04ca49.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750 2x" src="https://images.prismic.io/evernote/19769d48-26ae-47df-bd1c-578fcd4e1645_character-profile-on-64f460c04ca49.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750" style={{ color: 'transparent' }} />
                                        </figure>
                                    </div>
                                    <div>
                                        <h5 className="headling headline--5 mb-2 pr-6 font-semibold lg:pr-8">Character profile</h5>
                                        <p className="body-text body-text--2 pr-6 text-grey-25 lg:pr-8">Flesh out your characters, major and minor, to make them memorable.</p>
                                    </div>
                                </a>
                                <a className="flex h-full flex-col justify-between rounded-lg border border-black py-6 pl-6 text-black transition-all ease-in-out hover:-translate-y-px hover:shadow-button lg:py-8 lg:pl-8" href="https://www.evernote.com/shard/s308/sh/d6b8cde3-423a-46a6-990f-f0c95f252e58/47946f5478df3e82212da3248c975843?displayMode=template" rel="noreferrer">
                                    <div>
                                        <h6 className="headline headline--6 mb-6 pr-6 font-bold uppercase lg:pr-8">school</h6>
                                        <figure className="mb-10 w-full rounded-t-md bg-white">
                                            <img alt="" loading="lazy" width="352" height="240" decoding="async" data-nimg="1" className="h-full w-full object-cover" srcSet="https://images.prismic.io/evernote/214a5e4c-5cb9-4c57-8c81-f530069d72e2_dorm-checklist-on-64f460c410e7b.webp?auto=compress%2Cformat&amp;fit=max&amp;w=384 1x, https://images.prismic.io/evernote/214a5e4c-5cb9-4c57-8c81-f530069d72e2_dorm-checklist-on-64f460c410e7b.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750 2x" src="https://images.prismic.io/evernote/214a5e4c-5cb9-4c57-8c81-f530069d72e2_dorm-checklist-on-64f460c410e7b.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750" style={{ color: 'transparent' }} />
                                        </figure>
                                    </div>
                                    <div>
                                        <h5 className="headling headline--5 mb-2 pr-6 font-semibold lg:pr-8">Dorm checklist</h5>
                                        <p className="body-text body-text--2 pr-6 text-grey-25 lg:pr-8">Check off all the new stuff you’ll need to make your dorm room feel like home.</p>
                                    </div>
                                </a>
                                <a className="flex h-full flex-col justify-between rounded-lg border border-black py-6 pl-6 text-black transition-all ease-in-out hover:-translate-y-px hover:shadow-button lg:py-8 lg:pl-8" href="https://www.evernote.com/shard/s308/sh/358374a4-9eab-4ad5-bbd0-8b5de029749d/e18409b632762f4f575ecee4520a6b8b?displayMode=template" rel="noreferrer">
                                    <div>
                                        <h6 className="headline headline--6 mb-6 pr-6 font-bold uppercase lg:pr-8">personal well being</h6><figure className="mb-10 w-full rounded-t-md bg-white">
                                            <img alt="" loading="lazy" width="352" height="240" decoding="async" data-nimg="1" className="h-full w-full object-cover" srcSet="https://images.prismic.io/evernote/83f8d8d5-ba6e-4e82-b6ec-76cf6f0ceac6_save-money-on-64f460ce99a0f.webp?auto=compress%2Cformat&amp;fit=max&amp;w=384 1x, https://images.prismic.io/evernote/83f8d8d5-ba6e-4e82-b6ec-76cf6f0ceac6_save-money-on-64f460ce99a0f.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750 2x" src="https://images.prismic.io/evernote/83f8d8d5-ba6e-4e82-b6ec-76cf6f0ceac6_save-money-on-64f460ce99a0f.webp?auto=compress%2Cformat&amp;fit=max&amp;w=750" style={{ color: 'transparent' }} />
                                        </figure>
                                    </div>
                                    <div>
                                        <h5 className="headling headline--5 mb-2 pr-6 font-semibold lg:pr-8">Save money</h5>
                                        <p className="body-text body-text--2 pr-6 text-grey-25 lg:pr-8">Track your income and expenses and how they combine to reach your financial goals.</p>
                                    </div>
                                </a>
                            </div>
                            <div className="mx-auto">
                                <a className="rounded-md border border-black text-center transition-all ease-in-out min-w-[154px] py-2  px-10 bg-black text-colors-eggshell hover:bg-colors-eggshell hover:text-black block w-auto undefined" href="/templates">
                                    <span className="whitespace-nowrap font-medium  text-[16px]  undefined">All templates</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section border-y border-accent-dark bg-white py-16">
                    <div className="rich-wrapper--icons-grid container"><div className="text-center">
                        <h2>More than a notebook</h2>
                    </div>
                        <div className="grid grid-cols-2 gap-x-4 gap-y-12 lg:grid-cols-4 lg:gap-x-20 lg:gap-y-14">
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fmeetings.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fmeetings.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fmeetings.webp&amp;w=128&amp;q=75" style={{ color: "transparent" }} />
                                </figure>
                                <div className="w-full px-0.5"><h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">Meetings</h5>
                                    <p>Make meeting prep a breeze by linking notes to your calendar events.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fto-dos.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fto-dos.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fto-dos.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure>
                                <div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">To-dos</h5>
                                    <p>Give your memory a break by adding reminders to your tasks.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fprojects.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fprojects.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fprojects.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure>
                                <div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">Projects</h5>
                                    <p>Customize your Home to show everything you need for your next big project.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fgoals.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fgoals.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fgoals.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure><div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">Professional goals</h5>
                                    <p>Quickly create the perfect plan to help you get organized—and get ahead.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fschool.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fschool.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fschool.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure>
                                <div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">School</h5>
                                    <p>Stay on top of your assignments and never miss a due date.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fwriting.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fwriting.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fwriting.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure>
                                <div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">Writing</h5>
                                    <p>Express your ideas and find them quickly with search and keyword tags.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fvacations.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fvacations.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fvacations.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure>
                                <div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">Vacations</h5>
                                    <p>Let yourself relax. Keep the info you need for your getaway all in one spot.</p>
                                </div>
                            </div>
                            <div>
                                <figure className="mb-4 h-16 w-16">
                                    <img alt="icon" loading="lazy" width="64" height="64" decoding="async" data-nimg="1" className="object-cover" srcSet="/_next/image?url=%2Fimages%2Ficons%2Fpets.webp&amp;w=64&amp;q=75 1x, /_next/image?url=%2Fimages%2Ficons%2Fpets.webp&amp;w=128&amp;q=75 2x" src="/_next/image?url=%2Fimages%2Ficons%2Fpets.webp&amp;w=128&amp;q=75" style={{ color: 'transparent' }} />
                                </figure>
                                <div className="w-full px-0.5">
                                    <h5 className="body-text body-text--1 mb-2 font-semibold leading-normal">Pets</h5>
                                    <p>Walking schedule—check. Vet’s number—check. The mess on the rug—uhh...</p>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <a className="rounded-md border border-black text-center transition-all ease-in-out  hover:-translate-y-px hover:shadow-button min-w-[320px] py-2.5 lg:py-5 px-10 bg-accent text-colors-eggshell mt-6 inline-block lg:mt-16 undefined" href="https://accounts.evernote.com/get-started?platform=windows&amp;browser=chrome&amp;osVersion=10.0&amp;ctaType=signup&amp;experiment=no_experiment&amp;redirect_to=https%3A%2F%2Fevernote.com%2Fdownload-evernote&amp;website_id=073e81e7-72be-4686-9a85-1470b4d4ee9c&amp;is_obe=true" rel="noreferrer">
                                <span className="whitespace-nowrap font-medium  text-[18px] undefined">Get Evernote free</span>
                            </a>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}

export default WhyUs