import React, { useEffect } from "react";
import styles from "../styles/JobTile.module.scss";
import jobTile from "../assets/job-tile.svg";

const JobTile: React.FC = () => {
  useEffect(() => {
    const animationLoop = () => {
      const obj = document.querySelector('.jobTileBackground') as HTMLObjectElement;
      const svg = obj?.contentDocument?.querySelector('svg');
      if (!svg) return;

      // Get all the groups with type assertion
      const group5Days = svg.querySelector('g[id="5 days"]') as HTMLElement;
      const group10Days = svg.querySelector('g[id="10 days"]') as HTMLElement;
      const group15Days = svg.querySelector('g[id="15 days"]') as HTMLElement;

      if (!group5Days || !group10Days || !group15Days) return;

      // Initial state - hide all except 5 days
      [group5Days, group10Days, group15Days].forEach(group => {
        group.style.transition = 'all 1.2s cubic-bezier(0.4, 0, 0.2, 1)';
        group.style.opacity = '0';
      });
      
      // Show only 5 days initially
      group5Days.style.opacity = '1';

      const sequence = async () => {
        await new Promise(resolve => setTimeout(resolve, 3000));

        // Transition to 10 days
        group5Days.style.opacity = '0';
        group10Days.style.opacity = '1';
        group15Days.style.opacity = '0';
        
        await new Promise(resolve => setTimeout(resolve, 3000));

        // Transition to 15 days
        group5Days.style.opacity = '0';
        group10Days.style.opacity = '0';
        group15Days.style.opacity = '1';
        
        await new Promise(resolve => setTimeout(resolve, 3000));

        // Back to 5 days
        group5Days.style.opacity = '1';
        group10Days.style.opacity = '0';
        group15Days.style.opacity = '0';
      };

      sequence();
    };

    // Start animation when SVG loads
    const obj = document.querySelector('.jobTileBackground') as HTMLObjectElement;
    let intervalId: NodeJS.Timeout;
    
    const startAnimation = () => {
      animationLoop();
      intervalId = setInterval(animationLoop, 9000);
    };

    obj?.addEventListener('load', startAnimation);

    return () => {
      if (intervalId) clearInterval(intervalId);
      const obj = document.querySelector('.jobTileBackground') as HTMLObjectElement;
      obj?.removeEventListener('load', startAnimation);
    };
  }, []);

  return (
    <div className={styles.jobTileContainer}>
      <object 
        data={jobTile}
        type="image/svg+xml"
        className="jobTileBackground"
      />
    </div>
  );
};

export default JobTile;
