import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { FaArrowUp, FaArrowDown } from "react-icons/fa"; // Import icons for metrics
import { FaChevronRight } from 'react-icons/fa6';  // Importing the modern arrow icon
import Sidebar from './Sidebar'; 
import JobTile from './JobTile'; 
import Tracker from './Tracker';  // Import the new Tracker component
import JobDetailsModal, { JobData } from './JobDetailsModal';
import '../styles/components/dashboard.scss';
import axiosClient from '../axios-client';

const Dashboard: React.FC = () => {
  const [jobResults, setJobResults] = useState<any[]>([]);
  const [error, setError] = useState('');
  const [greeting, setGreeting] = useState('Good Morning');  // For dynamic greeting
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [selection, setSelection] = useState<JobData | null>(null);

  // Function to set the greeting based on the current time
  const determineGreeting = () => {
    const currentHour = new Date().getHours();
    
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour < 18) {
      setGreeting('Good Afternoon');
    } else {
      setGreeting('Good Evening');
    }
  };

  useEffect(() => {
    determineGreeting();  // Determine greeting on component mount

    const fetchUserData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user && user._id) {
          setUser(user);
        } else {
          navigate('/auth/login');
        }
      } catch (error) {
        setError('Failed to fetch user data.');
      }
    };
    
    fetchUserData();
  }, [navigate]);

  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        const response = await axiosClient.get('/jobs/suited-jobs');
        const data = response.data;
        setJobResults(data.slice(0, 12)); // Show only the first 12 jobs
      } catch (error) {
        setError('Failed to fetch recommended jobs.');
        console.error('Error fetching jobs:', error);
      }
    };

    if (user?._id) { // Only fetch jobs if user is logged in
      fetchJobListings();
    }
  }, [user]); // Add user as dependency to refetch when user changes

  const renderProgressCircle = (score: number, goal: number, percentage: number) => {
    const radius = 70;
    const stroke = 8;
    const normalizedRadius = radius - stroke / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
      <svg
        height={radius * 2}
        width={radius * 2}
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        className="progress-circle"
      >
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%">
            <stop offset="0%" stopColor="#43CC48" />
            <stop offset="100%" stopColor="#216624" />
          </linearGradient>
        </defs>
        <g transform={`rotate(90, ${radius}, ${radius})`}>
          <circle
            stroke="url(#gradient)"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            strokeDashoffset={strokeDashoffset}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            style={{ transition: "stroke-dashoffset 0.35s ease-in-out" }}
            strokeLinecap="round"
          />
        </g>
        <text
          x="50%"
          y="48%"
          textAnchor="middle"
          fontSize="30px"
          fontWeight="bold"
          fill="#333333"
        >
          {goal}
        </text>
        <text
          x="50%"
          y="63%"
          textAnchor="middle"
          fontSize="14px"
          fill="#A2A2A2"
        >
          Goal
        </text>
      </svg>
    );
  };

  const handleJobSelection = (job: JobData) => {
    setSelection(job);
  };

  const renderJobSection = () => {
    if (error) {
      return <div className="error-message">{error}</div>;
    }

    if (jobResults.length === 0 && !error) {
      return (
        <div className="job-tiles-container">
          <div className="no-jobs-message">
            No matching jobs found at the moment. Please check back later or adjust your preferences.
          </div>
        </div>
      );
    }

    return (
      <div className="job-tiles-container">
        {jobResults.map((job, index) => (
          <JobTile 
            key={index} 
            job={job} 
            userId={user?._id} 
            handleSelection={handleJobSelection}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard">
        {/* Greeting Section */}
        <header className="dashboard-header">
          {user ? (
            <h1 className="greeting">{greeting}, {user.firstName}!</h1>
          ) : (
            <h1 className="greeting">{greeting}!</h1>
          )}
        </header>

        {/* Metrics Section */}
        <div className="job-tiles-container">
          {/* Weekly Goal */}
          <div className="job-tile stat">
            <div className="stat-text">
              <h2>Weekly Goal</h2>
              <div className="weekly-goal-content">
                <div className="weekly-metric-section">
                  <div className="stat-number-container">
                    <p className="stat-number">
                      <strong>119</strong>
                    </p>
                  </div>
                  <p className="stat-label weekly-label">jobs applied</p>
                </div>
                <div className="goal-visualization">
                  {renderProgressCircle(119, 140, (119/140) * 100)}
                </div>
              </div>
            </div>
          </div>

          {/* Applications Applied */}
          <div className="job-tile stat">
            <div className="stat-text">
              <h2>Applications Applied</h2>
              <div className="stat-number-container">
                <p className="stat-number">
                  <strong>158</strong>
                </p>
                <p className="stat-goal increase">
                  <FaArrowUp className="arrow-icon" /> 15%
                </p>
              </div>
              <p className="stat-label">compared to last week</p>
            </div>
          </div>

          {/* Companies Responded */}
          <div className="job-tile stat">
            <div className="stat-text">
              <h2>Companies Responded</h2>
              <div className="stat-number-container">
                <p className="stat-number">
                  <strong>32</strong>
                </p>
                <p className="stat-goal decrease">
                  <FaArrowDown className="arrow-icon" /> 10%
                </p>
              </div>
              <p className="stat-label">compared to last week</p>
            </div>
          </div>
        </div>

        {/* Job Matches Header */}
        {/*<div className="job-matches-header">
          <h2>Fresh Job Matches For You</h2>
          <a href="/all-matched-jobs" className="view-all-jobs">
            View All<span className="arrow"><FaChevronRight /></span>
          </a>
        </div>

        {renderJobSection()}
        */}

        {/* Tracker Header */}
        <div className="job-matches-header">
          <h2>Job Matches</h2>
          <Link to="/job-search" className="view-all-jobs">
            View All<span className="arrow"><FaChevronRight /></span>
          </Link>
        </div>

        {/* Tracker Component */}
        <Tracker />

      </div>

      {selection && (
        <JobDetailsModal
          job={selection}
          isOpen={Boolean(selection._id)}
          onClose={() => setSelection(null)}
        />
      )}
    </div>
  );
};

export default Dashboard;
